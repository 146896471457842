import React, { Component } from 'react'
import { Bar } from 'react-chartjs-2'
import { secondsToHms } from 'utilities/timeConverter'
import { withTranslation } from 'react-i18next'

class PlayerChartBar extends Component {
    state = {
        chartBarData: {
            labels: [],
            datasets: [
                {
                    label: 'Products',
                    data: [],
                    backgroundColor: '#FFF3F3'
                }
            ],
            displayTitle: true,
            displayLegend: true,
            legendPosition: 'right',
        }
    }

    componentDidMount() {
        this.getChartData()
    }

    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data) {
            this.getChartData()
        }
    }
    

    getChartData() {
        const { data } = this.props
        
        if (data.length !== 0) {
            // get all labels
            const labels = []
            data.map(m => labels.push(m.name))
            // get all values
            const datasetValues = []
            data.map(m => datasetValues.push(m.value))
            // Generate colors for bars
            const datasetColors = []
            for (let index = 0; index < datasetValues.length; index++) {
                datasetColors.push(`rgb(${[1, 2, 3].map(x => Math.random() * 256 | 0)}, .6)`)
            }

            const datasets = [
                {
                    data: datasetValues,
                    backgroundColor: datasetColors
                }
            ]
            let chartBarData = { ...this.state.chartBarData, labels, datasets }
            this.setState({ chartBarData })
        }
        else this.setState({ chartBarData: undefined })
    }
    

    render() {
        const { isTime, isMobile, t, i18n } = this.props
        const { chartBarData } = this.state
        return (
            <React.Fragment>
                {chartBarData ? <Bar
                        data={chartBarData}
                        options={{
                            title: {
                                display: false,
                                text: this.props.mainTitle,
                                fontSize: 20,
                                padding: 30
                            },
                            legend: {
                                display: this.state.displayLegend,
                                position: this.state.legendPosition,
                                fontSize: 25,
                                labels: {
                                    fontFamily: "Arial"
                                }
                            },
                            tooltips: {
                                mode: 'index',
                                intersect: false,
                                callbacks: {
                                    label: function (tooltipItem) {
                                        return isTime ? secondsToHms(tooltipItem.value, i18n.language) : tooltipItem.value
                                    }
                                }
                            },
                            scales: {
                                xAxes: [{
                                    display: isMobile ? false : true,
                                    gridLines: {
                                        color: "#E5E6E8",
                                        display: false,
                                    },
                                    ticks: {
                                        fontSize: 14,
                                        padding: 15,
                                        fontFamily: "Arial"
                                    }
                                }],
                                yAxes: [{
                                    gridLines: {
                                        zeroLineColor: '#E5E6E8'
                                    },
                                    ticks: {
                                        fontSize: isMobile ? 12 : 14,
                                        padding: 15,
                                        fontFamily: "Arial",
                                        callback: function (value) {
                                            return isTime ? secondsToHms(value, i18n.language) : value
                                        }
                                    }
                                }]
                            }
                        }}
                    />
                    : <p>{ t('stats.no_theme_stats') }</p>
                }
            </React.Fragment>
        )
    }
}

export default withTranslation()(PlayerChartBar)
