import React from 'react'
import { useTranslation } from 'react-i18next'

const Preloader = ({ size = '', centered = false }) => {
	const { t } = useTranslation()

	return (
		<div className={centered ? 'preloader-wrap centered' : 'preloader-wrap'}>
			<div className={'preloader ' + size}>
				<div className="box1"></div>
				<div className="box2"></div>
				<div className="box3"></div>
				<p className="text-800">{ t('common.loading') }...</p>
			</div>
			</div>
	)
}

export default Preloader