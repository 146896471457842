import http from './httpService'
import { apiUrl } from '../config.json'

const apiEndpoint = apiUrl + '/parent'

export async function getParents(page = 1, pageSize = 12) {
    return http.get(`${apiEndpoint}?page=${page}&pageSize=${pageSize}`)
}

export default {
    getParents
}