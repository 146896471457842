import http from './httpService'
import { apiUrl } from '../config.json'

const apiEndpoint = apiUrl + '/relativesrequest'

export async function getRelatives() {
    return http.get(apiEndpoint)
}

export async function relativesCreate(peopleId) {
    return http.post(apiEndpoint + '/create?peopleId=' + peopleId)
}

export async function relativesConfirm(id) {
    return http.post(apiEndpoint + '/confirm?id=' + id)
}

export async function relativesCancel(id) {
    return http.post(apiEndpoint + '/cancel?id=' + id)
}

export default {
    getRelatives,
    relativesCreate,
    relativesConfirm,
    relativesCancel
}