import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import imgStatsLockedBg from 'assets/images/stats-locked-bg.png'

const LockedStats = () => {
    const { t } = useTranslation()

    return (
        <div className="stats">
            <h2 className="title pb-lg">{ t('common.stats_title') }</h2>
            <div className="content-card content-placeholder content-placeholder--lg">
                <div className="stats-locked-bg">
                    <img src={imgStatsLockedBg} alt="locked"/>
                    <div className="stats-locked-bg__info">
                        <i className="icon-password"></i>
                        <h3 className="title pt-xs">{ t('stats.unavailable') }</h3>
                        <p className="pb-lg pt-xs">{ t('stats.unavailable_info') }</p>
                        <Link to="/prices" className="btn btn--brand">{ t('common.buy_subscription') }</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LockedStats
