import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { paymeMerchantId, clickServiceId, clickMerchantId } from 'config.json'

import imgClick from 'assets/images/click.png'
import imgPayme from 'assets/images/payme.svg'


const CoinModal = ({ isOpen, toggleModal, profileData }) => {
    const { t } = useTranslation()
    const [sum, setSum] = useState('')
    const [isFirstStage, setIsFirstStage] = useState(true)

    useEffect(() => {
        !isOpen && setTimeout(() => {
            setSum('')
            setIsFirstStage(true)
        }, 300)
    }, [isOpen])

    function handlePurchase(payType) {
        
        if (payType === 'payme') {
            const link = 'https://checkout.paycom.uz/' + btoa(`m=${paymeMerchantId};ac.people_id=${profileData.peopleId};a=${sum}00;c=https://cabinet.edumarket.uz/common/?trans=:transaction&peopleId=:account.people_id`)
            // console.log('Base64:', link)
            window.location.replace(link)
        }
        else {
            const link = `https://my.click.uz/services/pay?service_id=${clickServiceId}&merchant_id=${clickMerchantId}&amount=${sum}&transaction_param=${profileData.peopleId}&return_url=https://cabinet.edumarket.uz/common`
            // console.log('CLICK', link)
            window.location.replace(link)
        }
    }

    return (
        <div className={isOpen ? 'modal open' : 'modal'}>
            <div className="modal__content modal__content--coins">
                <button className="modal__dismisser" onClick={toggleModal}><i className="icon-cancel"></i></button>
                {isFirstStage
                    ? <React.Fragment>
                        <h2 className="title pb-layout-xs text-center"
                            dangerouslySetInnerHTML={{ __html: t('prices.deposit') }}></h2>
                        <div className="modal__coins-wrap">
                            <div className="form-group">
                                <input type="number" name="sum" id="sum"
                                    className="input"
                                    onChange={e => setSum(e.target.value)}
                                    value={sum}
                                    placeholder={ t('input.payment_sum') }
                                    required />
                            </div>
                            <button className="btn btn--brand"
                                disabled={sum === ''}
                                onClick={() => setIsFirstStage(false)}>{ t('common.continue') }</button>
                        </div>
                    </React.Fragment>
                    : <React.Fragment>
                        <h2 className="title pb-layout-xs text-center">{ t('prices.deposit_method') }</h2>
                        <div className="modal-boxes">
                            <div className="modal__box" onClick={() => handlePurchase('payme')}>
                                <img src={imgPayme} alt="Payme" />
                            </div>
                            <div className="modal__box" onClick={() => handlePurchase('click')}>
                                <img src={imgClick} className="click" alt="Click" />
                            </div>
                        </div>
                    </React.Fragment>
                }
            </div>
        </div>
    )
}

export default CoinModal
