import React, { Component } from 'react'
import { Bar } from 'react-chartjs-2'
import { withTranslation } from 'react-i18next'

class PlayerChartStackedBar extends Component {
    state = {
        chartBarData: {
            labels: [],
            datasets: [
                {
                    label: 'Products',
                    data: [],
                    backgroundColor: '#FFF3F3'
                }
            ],
            displayTitle: true,
            displayLegend: true,
            legendPosition: 'right',
        }
    }

    componentDidMount() {
        this.getChartData()
    }

    componentDidUpdate(prevProps) {
        if (this.props.rightCount !== prevProps.rightCount || this.props.wrongCount !== prevProps.wrongCount) {
            this.getChartData()
        }
    }

    getChartData() {
        const { rightCount, wrongCount } = this.props
        
        if (rightCount.length !== 0 || wrongCount.length !== 0) {
            // get all labels
            const labels = []
            rightCount.map(m => labels.push(m.name))
            // get right count
            const datasetRightCount = []
            rightCount.map(m => datasetRightCount.push(m.value))
            // get wrong count
            const datasetWrongCount = []
            wrongCount.map(m => datasetWrongCount.push(m.value))

            const datasets = [
                {
                    label: 'Правильные ответы',
                    data: datasetRightCount,
                    backgroundColor: 'rgba(75, 192, 192, 0.5)'
                },
                {
                    label: 'Неправильные ответы',
                    data: datasetWrongCount,
                    backgroundColor: 'rgba(255, 99, 132, 0.5)'
                }
            ]
            let chartBarData = { ...this.state.chartBarData, labels, datasets }
            this.setState({ chartBarData })
        }
        else this.setState({ chartBarData: undefined })
    }
    

    render() {
        const { isMobile, t } = this.props
        const { chartBarData } = this.state
        return (
            <React.Fragment>
                {chartBarData ? <Bar
                        data={chartBarData}
                        options={{
                            title: {
                                display: false,
                                text: this.props.mainTitle,
                                fontSize: 20,
                                padding: 30
                            },
                            legend: {
                                display: this.state.displayLegend,
                                position: this.state.legendPosition,
                                fontSize: 25,
                                labels: {
                                    fontFamily: "Arial"
                                }
                            },
                            tooltips: {
                                mode: 'index',
                                intersect: false
                            },
                            scales: {
                                xAxes: [{
                                    display: isMobile ? false : true,
                                    gridLines: {
                                        color: "#E5E6E8",
                                        display: false,
                                    },
                                    ticks: {
                                        fontSize: 14,
                                        padding: 15,
                                        fontFamily: "Arial"
                                    },
                                    stacked: true
                                }],
                                yAxes: [{
                                    gridLines: {
                                        zeroLineColor: '#E5E6E8'
                                    },
                                    ticks: {
                                        fontSize: isMobile ? 12 : 14,
                                        padding: 15,
                                        fontFamily: "Arial",
                                        beginAtZero: true,
                                        min: 0,
                                        // max: 10,
                                        stepSize: 2,
                                    },
                                    stacked: true
                                }]
                            }
                        }}
                    />
                : <p>{ t('stats.no_theme_stats') }</p>
            }
            </React.Fragment>
        )
    }
}

export default withTranslation()(PlayerChartStackedBar)
