export default {
    common: {
        continue: 'Продолжить',
        more: 'Подробнее',
        login_to_cab: 'Войти в кабинет',
        save: 'Сохранить',
        miss: 'Пропустить',
        logout: 'Выход',
        common: 'Общее',
        profile: 'Профиль',
        stats: 'Статистика игрока',
        tariffs: 'Подписка',
        children: 'Дети',
        parents: 'Родители',
        stats_title: 'Статистика',
        add: 'Добавить',
        no_relatives: 'Родственников пока нет',
        no_stats: 'Статистики нет',
        cta: 'Оплати тариф и получи бонусы!',
        buy_subscription: 'Купить подписку',
        loading: 'Загрузка',
        sum: 'сум',
        no_player_stats: 'По этому игроку статистики нет',
        apply: 'Применить',
        congrats: 'Поздравляем!',
        yes: 'Да',
        cancel: 'Отмена',
        success_deposit: 'Ваш кошелек пополнен'
    },
    scores: {
        quests: 'успешных <br /> заданий',
        answers: 'правильных <br /> ответов',
        total: 'баллов <br /> за все время'
    },
    profile: {
        my_data: 'Мои данные',
        photo: 'Фото<br />профиля',
        change_password: 'Сменить пароль',
        fb_title: 'Facebook для быстрого входа',
        fb_connect: 'Подключить',
        fb_connected: 'Подключен',
        fb_reconnect: 'Привязать другой аккаунт?'
    },
    stats: {
        unavailable: 'Статистика недоступна',
        unavailable_info: 'Для того что бы просматривать статистику вам необходимо приобрести подписку',
        general: 'Общая',
        general_subject: 'Общий',
        result: 'Общий результат',
        time_spent: 'Общее время в игре',
        result_avg: 'Средний результат',
        time_spent_avg: 'Среднее время в игре',
        right: 'Правильные ответы',
        wrong: 'Неправильные ответы',
        by_subject: 'По предмету',
        choose_subject: 'Выберите предмет',
        all_scores: 'Общее количество очков',
        all_time: 'Общее время в игре',
        all_answers: 'Показатель ответов',
        no_subject_stats: 'По данному предмету статистики нет',
        no_theme_stats: 'По данной теме статистики нет',
        no_player_stats: 'Нет статистики по данному игроку',
        by_theme: 'По теме',
        choose_theme: 'По теме',
    },
    prices: {
        day: ' день',
        days: ' дней',
        month_stats: ' месяц статистики',
        month_stats_2: ' месяца статистики',
        month_stats_3: ' месяцев статистики',
        year_stats: '1 год статистики',
        month: ' месяц',
        month_2: ' месяца',
        month_3: ' месяцев',
        year: ' 1 год',
        payment_method: 'Выберите способ оплаты',
        active_until: 'Ваша текущая подписка активна до ',
        pay_until: 'Оплата тарифного плана на ',
        deposit_method: 'Способ пополнения',
        deposit: 'Введите сумму пополнения<br/>вашего баланса',
        test_day: 'Тестовый день',
        free: 'Бесплатно',
        free_day: 'День подписки бесплатно',
        get_sub: 'Приобрести подписку',
        purchase_free_sub: 'Вы приобретаете одноразовую бесплатную подписку на день',
        purchase_sub: 'Вы подтверждаете покупку за ',
        success_sub: 'Вы успешно приобрели подписку'
    },
    relatives: {
        requests: 'Запросы',
        no_name: 'Имя и фамилия не указаны',
        reject: 'Отклонить запрос',
        accept: 'Принять запрос',
    },
    auth: {
        welcome: 'Добро пожаловать в родительский личный кабинет',
        register_title: 'Регистрация',
        login: 'Войти',
        register: 'Зарегистрироваться',
        agreement: `
            Нажатием на кнопку "Зарегистрироваться" я даю свое согласие на обработку персональных данных в соответствии с указанными <a href="https://edumarket.uz/privacy" class="text-underline" target="_blank" rel="noopener noreferrer">здесь</a> условиями. Если вы отказываетесь от обработки персональных данных, не нажимайте кнопку "Зарегистрироваться".
        `,
    },
    input: {
        name: 'Имя пользователя',
        first_name: 'Имя',
        last_name: 'Фамилия',
        middle_name: 'Отчество',
        password: 'Пароль',
        password_old: 'Старый пароль',
        password_new: 'Новый пароль',
        password_confirm: 'Потворите новый пароль',
        phone: 'Номер телефона',
        phone_helper: 'Укажите номер телефона с полным кодом',
        role: 'Роль',
        birthyear: 'Год рождения (2009)',
        birthyear_helper: 'Укажите год рождения, например "2009"',
        gender: 'Пол',
        country: 'Страна',
        continent: 'Континент',
        agreement: 'Я даю согласие на обработку моих персональных данных.',
        male: 'Муж',
        female: 'Жен',
        parent: 'Родитель',
        player: 'Ребенок',
        children: 'Дети',
        user_id: 'ID пользователя',
        payment_sum: 'Сумма пополнения'
    },
    errors: {
        login: 'Поле "Логин" не может быть пустым и короче 3 символов',
        login_symbols: 'Поле "Логин" не может содержать спец символы или пробелы',
        password: 'Поле "Пароль" не может быть пустым и короче 5 символов',
        password_new: 'Поле "Новый пароль" не может быть пустым и короче 5 символов',
        password_equal: 'Новые пароли должны совпадать',
        role: 'Поле "Роль" не может быть пустым',
        gender: 'Поле "Пол" не может быть пустым',
        birthyear_empty: 'Поле "Год рождения" не может быть пустым и короче 4 символов',
        birthyear: 'Поле "Год рождения" не может быть больше настоящего времени',
        birthyear_min: 'Поле "Год рождения" не может быть меньше 1950',
        country: 'Поле "Страна" не может быть пустым',
        continent: 'Поле "Континент" не может быть пустым',
        agreement: 'Вы должны дать свое согласие на обработку данных чтобы зарегистрироваться.',
        first_name: 'Поле "Имя" не может быть пустым',
        first_name_symbols: 'Поле "Имя" не может содержать спец символы или пробелы',
        last_name: 'Поле "Фамилия" не может быть пустым',
        last_name_symbols: 'Поле "Фамилия" не может содержать спец символы или пробелы',
        middle_name: 'Поле "Отчество" не может быть пустым',
        phone: 'Поле "Номер телефона" не может быть пустым',
        phone_limit: 'Поле "Номер телефона" должно быть равно 12 символам',
        email: 'Поле "Email" не может быть пустым',
        email_format: 'Недопустимый формат электронной почты',
    },
    toast: {
        success: {
            profile: 'Персональные данные успешно сохранены',
            password: 'Пароль успешно изменен',
            request_sent: 'Ваш запрос отправлен пользователю',
            request_added: 'Пользователь добавлен',
            request_rejected: 'Ваш отказ отправлен пользователю',
            fb_connected: 'Ваш профиль успешно привязан'
        }
    },
    continents: {
        africa: 'Африка',
        antarctica: 'Антарктида',
        asia: 'Азия',
        australia: 'Австралия',
        europe: 'Европа',
        north_america: 'Северная Америка',
        south_america: 'Южная Америка'
    },
    instructions: {
        name: 'Инструкция для родителей',
        title: 'Инструкция пользования персональным кабинетом родителя',
        subtitle: 'Уважаемые родители, одним из главных преимуществ платформы Edu&nbsp;Market является персональный кабинет для родителя. Функционал персонального кабинета представляет состоит из 5 блоков:',
        point_1: 'Блок «Общее» включает в себя все ID-номера игроков, с краткими показателями (успешно выполненные задания, правильные ответы, баллы за всё время)',
        point_2: 'Блок «Профиль» – персональная страничка родителя с его персональными данными, е-адресом и номером телефона',
        point_3: 'Блок «Статистика игрока» – анализ результатов ребенка (соотношение верных и неверных решений) в цифровом и графическом вариантах для полной наглядности динамики логического и общего развития игрока во времени и сложности поставленных задач с возможностью фильтрации по датам',
        point_4: 'Блок «Родители» - странички родителей с ID-номерами и наглядной статистикой результатов выполненных заданий ребенком',
        point_5: 'Блок «Тарифы» - тарификация услуги статистики в зависимости от периодов времени- за 1 месяц, за 3 месяца, за полгода, за год'
    }
}