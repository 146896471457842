import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import 'react-toastify/dist/ReactToastify.css'

import LoginForm from './components/auth/LoginForm'
import RegisterForm from './components/auth/RegisterForm'
// import ChangePasswordForm from './components/auth/ChangePasswordForm'
import Content from './components/Content'
import ProtectedRoute from './components/common/ProtectedRoute'

const App = () => {

	const LangContext = React.createContext('ru')
	const { i18n } = useTranslation()

	return (
		<LangContext.Provider value={i18n.language}>
			<ToastContainer />
			<Switch>
				<Route path="/login" component={LoginForm} />
				<Route path="/register" component={RegisterForm} />
				{/* <Route path="/changePassword" component={ChangePasswordForm} /> */}
				<ProtectedRoute path="/" component={Content} />
			</Switch>
		</LangContext.Provider>
	)
}

export default App

