import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import auth from 'services/authService'
import Sidebar from './Sidebar'
import LangSwitcher from 'components/common/LangSwitcher'
import { withTranslation } from 'react-i18next'

const _body = document.querySelector('body')

class Header extends Component {
    state = {
        isSidebarCollapsed: true,
        profileData: this.props.profileData
    }
    
    static getDerivedStateFromProps(props, state) {
        if (props.profileData !== state.profileData) {
            return {profileData: props.profileData}
        }

        return null
    }

    handleMenuToggle = () => {
        _body.classList.toggle('o-hidden')
        this.setState({isSidebarCollapsed: !this.state.isSidebarCollapsed})
    }

    handleMenuCollapse = () => {
        _body.classList.remove('o-hidden')
        this.setState({isSidebarCollapsed: true})
    }

    handleLogout = () => {
        auth.logout()
        this.props.history.replace('/login')
    }
    
    render() {
        const { isSidebarCollapsed, profileData } = this.state
        const { t, toggleCoinModal } = this.props

        return (
            <header className={isSidebarCollapsed ? 'header' : 'header open'}>
                <div className="header-wrap">
                    <Link to="/" className="header__logo">Edu Market</Link>
                    <LangSwitcher />
                    <div className="hamburger-wrap">
                        <div className={isSidebarCollapsed ? 'hamburger' : 'hamburger open'}
                            onClick={this.handleMenuToggle}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                    <Sidebar profileData={profileData}
                        onLinkClick={this.handleMenuCollapse}
                        toggleCoinModal={toggleCoinModal}
                    />
                </div>
                <button onClick={this.handleLogout} className="sidebar__link">
                    <i className="icon-exit"></i>
                    <span>{ t('common.logout') }</span>
                </button>
            </header>
        )
    }
}

export default withTranslation()(withRouter(Header))
