import http from './httpService'
import { apiUrl } from '../config.json'

const apiEndpoint = apiUrl + '/subject'

function subjectUrl(id) {
    return `${apiEndpoint}/${id}`
}

export function getSubjects() {
    return http.get(apiEndpoint)
}

export function getSubject(subjectId) {
    return http.get(subjectUrl(subjectId))
}

export function saveSubject(subject) {
    if (subject.id) {
        const body = { ...subject }
        delete body.id

        return http.put(subjectUrl(subject.id), body)
    }

    return http.post(apiEndpoint, subject)
}

export function deleteSubject(subjectId) {
    return http.delete(subjectUrl(subjectId))
}