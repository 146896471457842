import http from './httpService'
import { apiUrl } from '../config.json'
import { formatDate } from 'utilities/timeConverter'

const apiEndpoint = apiUrl + '/statistic/player'
const generalPeriod = `?startDate=2019-01-01&endDate=${formatDate(new Date())}`

export async function getPlayersMinStatsById(id) {
    return http.get(`${apiEndpoint}/min/${id}`)
}

export async function getPlayerStats(playerId) {
    return http.get(`${apiEndpoint}/${playerId}/period${generalPeriod}`)
}

export async function getPlayerStatsBySubject(playerId, subjectId, startDate, endDate) {
    return http.get(`${apiEndpoint}/${playerId}/subject/${subjectId}/${makeDateQuery(startDate, endDate)}`)
}

export async function getPlayerStatsByTheme(playerId, themeId, startDate, endDate) {
    return http.get(`${apiEndpoint}/${playerId}/theme/${themeId}/${makeDateQuery(startDate, endDate)}`)
}

export async function getSelfStats() {
    return http.get(`${apiEndpoint}/period${generalPeriod}`)
}

export async function getSelfStatsBySubject(subjectId, startDate, endDate) {
    return http.get(apiEndpoint + `/subject/${subjectId}/${makeDateQuery(startDate, endDate)}`)
}

export async function getSelfStatsByTheme(themeId, startDate, endDate) {
    return http.get(apiEndpoint + `/theme/${themeId}/${makeDateQuery(startDate, endDate)}`)
}

function makeDateQuery(startDate, endDate) {
    return `period?startDate=${formatDate(startDate)}&endDate=${formatDate(endDate)}`
}

export default {
    getPlayersMinStatsById,
    
    getPlayerStats,
    getPlayerStatsBySubject,
    getPlayerStatsByTheme,

    getSelfStats,
    getSelfStatsBySubject,
    getSelfStatsByTheme
}