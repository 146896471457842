import http from './httpService'
import { apiUrl } from '../config.json'
import authService from './authService'

const apiEndpoint = apiUrl + '/people'

export async function getProfile() {
    const res = await http.get(apiEndpoint + '/profile')
    if (res && res.status === 400) {
        authService.logout()
        window.location = '/login'
    }

    return  res
}

export async function editProfile(data) {
    return await http.put(apiEndpoint + '/profile', data)
}

export async function getBalance() {
    return await http.get(apiEndpoint + '/balance')
}

export default {
    getProfile,
    editProfile,
    getBalance
}