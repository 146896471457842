import React, { Component } from 'react'
import FacebookLogin from 'react-facebook-login'
import { photoUpload, photoDownload } from 'services/photoService'
import { editProfile } from 'services/peopleService'
import { toast } from 'react-toastify'
import { withTranslation } from 'react-i18next'
import { facebookAppId } from 'config.json'
import { connectFacebook } from 'services/authService'
import { IMaskInput } from 'react-imask'

import ChangePassword from './ChangePassword'
import Preloader from './common/Preloader'

import imgBoyPlaceholder from 'assets/images/users/boy.png'
import imgGirlPlaceholder from 'assets/images/users/girl.png'
import Swal from 'sweetalert2'

export class Profile extends Component {

    state = {
        avatar: '',
        inputData: {
            firstName: '',
            lastName: '',
            middleName: '',
            birthYear: '',
            email: '',
            phoneMobile: ''
        },
        errors: {}
    }

    componentDidMount() {
        this.getProfileData()
        window.fbAsyncInit = function() {
            window.FB.init({
                appId      : facebookAppId,
                cookie     : true,  // enable cookies to allow the server to access
                
                xfbml      : true,  // parse social plugins on this page
                version    : 'v2.1'
            });
        };

        // Load the SDK asynchronously
        (function(d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "//connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }

    componentDidUpdate(prevProps) {
        if (prevProps.profileData.userId !== this.props.profileData.userId || prevProps.profileData.userId !== this.state.inputData.userId) {
            this.getProfileData()
        }
    }

    getProfileData() {
        const { profileData } = this.props
        if (profileData.photoPath)
            this.setState({inputData: {...profileData}, avatar: profileData.photoPath})
        else
            this.setState({inputData: {...profileData}})
    }

    handleAvatarDownload = async () => {
        const res = await photoDownload()

        if (res) {

            if (res.status === 200) {
                const base64String = Buffer.from(res.data, 'binary').toString('base64')
                this.setState({ avatar: 'data:image/jpg;base64,' + base64String })
                this.props.setNewAvatar('data:image/jpg;base64,' + base64String)
            }
        }
    }

    handleAvatarUpload = async e => {
        const form = new FormData()
        form.append('file', e.target.files[0])

        const res = await photoUpload(form)
        if (res.status === 200) {

            this.handleAvatarDownload()
        }
    }

    handleInputChange = (e, val = '') => {
        const data = {...this.state.inputData}
        data[e.target.name] = e.target.value
        this.setState({inputData: data})
    }

    handleBirthYearChange = e => {
        const data = {...this.state.inputData}
        if (e.target.value.length > 4) e.target.value = e.target.value.substring(0, 4)
        
        data.birthYear = e.target.value

        this.setState({inputData: data})
    }

    generateFormClass = name => {
        const { errors } = this.state
        return errors[name] ? 'form-group form-group--error' : 'form-group'
	}

    handleSaveProfile = async () => {
        const { firstName, lastName, email, birthYear, phoneMobile } = this.state.inputData
        const { t } = this.props

        const errors = {}
        if (firstName === '' || /^[A-Za-zА-Яа-я]+$/.test(firstName) === true)
            delete errors.firstName
        else
            errors.firstName = t('errors.first_name_symbols')

        if (lastName === '' || /^[A-Za-zА-Яа-я]+$/.test(lastName) === true)
            delete errors.lastName
        else
            errors.lastName = t('errors.last_name_symbols')
            
		// eslint-disable-next-line
        if (/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/.test(email) === true || email === '')
			delete errors.email
		else
            errors.email = t('errors.email_format')

        if (birthYear === '' || birthYear.length < 4)
            errors.birthYear = t('errors.birthyear_empty')
        else if (parseInt(birthYear) > new Date().getFullYear())
            errors.birthYear = t('errors.birthyear')
        else if (parseInt(birthYear) < new Date('01.01.1950').getFullYear())
            errors.birthYear = t('errors.birthyear_min')
        else
            delete errors.birthYear
	
        if (phoneMobile !== '') {
            if (phoneMobile.length < 12 || phoneMobile.length > 12)
                errors.phoneMobile = t('errors.phone_limit')
            else
                delete errors.phoneMobile
        }
        
        if (Object.keys(errors).length > 0 && errors.constructor === Object)
			return this.setState({errors})
        
        this.setState({errors: {}})
        const { inputData } = this.state
        const profileData = {
            firstName: inputData.firstName,
            lastName: inputData.lastName,
            middleName: inputData.middleName,
            birthYear: Number(inputData.birthYear),
            gender: inputData.gender,
            phoneMobile: inputData.phoneMobile,
            email: inputData.email,
            countryId: inputData.countryId,
        }

        if (profileData.phoneMobile === '') delete profileData.phoneMobile

        
        try {
            const res = await editProfile(profileData)
            if (res && res.status === 200) {
                Swal.fire(
                    t('toast.success.profile'),
                    '',
                    'success'
                )
            }
        } catch (error) {
            
        }
    }

    generateAvatar = () => {
        const { profileData } = this.props
        const { avatar } = this.state
        
        if (profileData.gender) {
            if (avatar === '') {
                if (profileData.gender === 'Male')
                    return <div style={{ backgroundImage: 'url(' + imgBoyPlaceholder + ')' }}></div>
                else 
                    return <div style={{ backgroundImage: 'url(' + imgGirlPlaceholder + ')' }}></div>
            }

            return <div style={{ backgroundImage: 'url(' + avatar + ')' }}></div>
        }
        return <div></div>
    }

    handleFacebookReconnect = async res => {
        const FB = window.FB
        const self = this
        FB.login(function(res) {
            if (res && res.status === 'connected') {
                FB.logout(function() {
                    FB.login(function(res) {
                        if (res.authResponse) {
                            self.handleResponseFacebook({...res.authResponse, status: res.status})
                        }
                    })
                })
            }
            else {
                if (res.authResponse) {
                    self.handleResponseFacebook({...res.authResponse, status: res.status})
                }
            }
        })
    }

    handleResponseFacebook = async res => {
        const { t } = this.props
        
        if (res.status === 'unknown') {
            return
        }
        
        try {
            const response = await connectFacebook(res.accessToken)
            if (response && response.status === 200) {
                toast.success(t('toast.success.fb_connected'))
            }
        } catch (error) {
            console.log(error)
        }
    }

    render() {
        const { inputData: data, errors } = this.state
        const { t, profileData } = this.props

        return (
            <div className="cabinet">
                <h1 className="title pb-xl">{ t('common.profile') }</h1>

                {!Object.keys(profileData).length > 0 && profileData.constructor === Object
                    ? <Preloader size="lg" centered />
                    : <div className="animated fadeInUp content-card relative">
                        <h3 className="subtitle pb-lg">{ t('profile.my_data') }</h3>
                        <div className="cabinet__avatar">
                            <label htmlFor="uploadAvatar">
                                { this.generateAvatar() }
                                <div className="cabinet__avatar-placeholder">
                                    <i className="icon-photo"></i>
                                    <span dangerouslySetInnerHTML={{__html: t('profile.photo')}}></span>
                                </div>
                            </label>
                            <input type="file" name="file" className="hide" onChange={this.handleAvatarUpload} id="uploadAvatar" />
                        </div>
                        <div className="cabinet-row pb-sm animated fadeInUp">
                            <div className="cabinet-col">
                                <div className={this.generateFormClass('lastName')}>
                                    <input type="text" name="lastName" id="lastName"
                                        className="input"
                                        onChange={this.handleInputChange}
                                        value={data.lastName}
                                        placeholder={ t('input.last_name') }
                                        required />
                                    <p className="form-group__label">{errors.lastName}</p>
                                </div>
                                <div className={this.generateFormClass('firstName')}>
                                    <input type="text" name="firstName" id="firstName"
                                        className="input"
                                        onChange={this.handleInputChange}
                                        value={data.firstName}
                                        placeholder={ t('input.first_name') }
                                        required />
                                    <p className="form-group__label">{errors.firstName}</p>
                                </div>
                                <div className="form-group">
                                    <input type="text" name="middleName" id="middleName"
                                        className="input"
                                        onChange={this.handleInputChange}
                                        value={data.middleName}
                                        placeholder={ t('input.middle_name') }
                                        required />
                                </div>
                            </div>
                            <div className="cabinet-col">
                                <div className={this.generateFormClass('birthYear')}>
                                    <input type="number" name="birthYear" id="birthYear"
                                        className="input"
                                        onChange={this.handleBirthYearChange}
                                        value={data.birthYear}
                                        placeholder={ t('input.birthyear') }
                                        title={ t('input.birthyear_helper') }
                                        required />
                                    <p className="form-group__label">{errors.birthYear}</p>
                                </div>
                                <div className={this.generateFormClass('phoneMobile')}>
                                    <IMaskInput
                                        mask='+{998} 00 000-00-00'
                                        radix="."
                                        value={data.phoneMobile}
                                        unmask={true}
                                        className="input"
                                        onAccept={
                                            (value, mask) => {
                                                const data = {...this.state.inputData}
                                                data.phoneMobile = mask._unmaskedValue
                                                this.setState({inputData: data})
                                            }
                                        }
                                        placeholder={ t('input.phone') }
                                    />
                                    <p className="form-group__label">{errors.phoneMobile}</p>
                                </div>
                                <div className={this.generateFormClass('email')}>
                                    <input type="email" name="email" id="email"
                                        className="input"
                                        onChange={this.handleInputChange}
                                        value={data.email}
                                        placeholder="Email"
                                        required />
                                    <p className="form-group__label">{errors.email}</p>
                                </div>
                            </div>
                        </div>
                        
                        <div className="pb-lg animated fadeInUp">
                            <h3 className="subtitle pb-lg pt-lg b-t">{ t('profile.fb_title') }</h3>
                            {profileData && profileData.facebookId
                                ? <div>
                                    { t('profile.fb_connected') }. <button
                                        onClick={this.handleFacebookReconnect}
                                        className="link text-brand">{ t('profile.fb_reconnect') }</button>
                                    </div>
                                : <FacebookLogin
                                    appId={facebookAppId}
                                    fields="name,email,picture"
                                    callback={this.handleResponseFacebook}
                                    cssClass="btn btn--brand btn--facebook-long"
                                    icon="icon-facebook"
                                    textButton={ t('profile.fb_connect') }
                                />
                            }
                        </div>
                        
                        <ChangePassword onSubmit={this.handleSaveProfile} />
                    </div>
                }
            </div>
        )
    }
}

export default withTranslation()(Profile)