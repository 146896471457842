import React, { Component } from 'react'
import { getPrices } from 'services/priceService'
import { createPayment } from 'services/paymentService'
import { updateJwtAndCookies } from 'services/authService'
import { withTranslation } from 'react-i18next'
import Preloader from 'components/common/Preloader'
import toNumberWithSpaces from 'utilities/toNumberWithSpaces'
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'

class Prices extends Component {
    state = {
        prices: [],
        user: this.props.profileData,
        isLoading: true,
    }

    async componentDidMount() {
        const res = await getPrices()
        this.setState({ isLoading: false })
        
        if (res && res.status === 200)
            this.setState({ prices: res.data.data })
    }

    static getDerivedStateFromProps(props, state) {
        if (props.profileData !== state.user)
            return { user: props.profileData }
        
        return null
    }
    
    handlePurchase = async (price) => {
        const { t } = this.props
        const res = await createPayment(price.id)
        
        if (res && res.status === 200) {
            updateJwtAndCookies(res)
            this.props.onPayment()
            Swal.fire(
                t('common.congrats'),
                t('prices.success_sub'),
                'success'
            )
        }
    }

    handleToggleModal = (price) => {
        const { t, i18n } = this.props
        if (!price) return

        let text
        if (price.amount === 0)
            text = t('prices.purchase_free_sub')
        else {
            if (i18n.language === 'ru')
                text = t('prices.purchase_sub') + toNumberWithSpaces(price.amount) + ' ' + t('common.sum') + '?'
            else
                text = t('prices.purchase_sub_1') + toNumberWithSpaces(price.amount) + t('prices.purchase_sub_2')
        }

        // if (getCurrentUser().pp_acc_all)
        //     text += '\n Ваша текущая подписка доступна до '
        //         + new Date(getCurrentUser().pp_acc_all.substr(getCurrentUser().pp_acc_all.indexOf(':') + 1) * 1000)
        //             .toLocaleDateString('ru-Ru')

        Swal.fire({
            title: t('prices.get_sub'),
            text,
            icon: 'question',
            showCancelButton: true,
            cancelButtonColor: '#e25935',
            confirmButtonText: t('common.yes'),
            cancelButtonText: t('common.cancel'),
            customClass: {
                confirmButton: 'btn btn--brand',
            }
        }).then((result) => {
            if (result.value) 
                this.handlePurchase(price)
        })
    }

    renderPrice = price => {
        const { t } = this.props
        if (price.amount !== 0)
            return toNumberWithSpaces(price.amount) + ' ' + t('common.sum')
        else
            return t('prices.free')
    }

    renderPriceText = price => {
        const { t } = this.props
        if (price.days === 1)
            return price.amount === 0 ? t('prices.test_day') : price.days + t('prices.day')
        
        if (price.days === 7) return price.days + t('prices.days')
        if (price.days === 30) return 1 + t('prices.month')
        if (price.days === 90) return 3 + t('prices.month_2')
        if (price.days === 180) return 6 + t('prices.month_3')
        if (price.days === 365) return t('prices.year')
    }

    render() {
        const { isLoading } = this.state
        const { t } = this.props

        return (
            <div className="prices">
                <div className="d-flex align-items-center justify-content-between pb-xl">
                    <h2 className="title">{ t('common.tariffs') }</h2>
                    {/* <p className="text-bold">У вас осталось 10 дней</p> */}
                    <button className="btn btn--brand btn--sm"
                        onClick={() => this.handleToggleModal({id: 13, amount: 0, days: 1})}>{ t('prices.free_day') }</button>
                </div>

                {isLoading
                    ? <Preloader size="lg" centered />
                    : <div className="animated fadeInUp pb-0">
                        <div className="prices-wrap">
                            
                            {this.state.prices.map((price, index) =>
                                index !== 0 && <div className="prices__item" key={price.id}>
                                    <div className="prices__item-wrap"
                                        onClick={() => this.handleToggleModal(price)}>
                                        <div className="prices__item-thumb">
                                            <img src={`/assets/images/prices/${index}.png`} alt="tariff"/>
                                        </div>
                                        <p className="pb-sm">{ this.renderPriceText(price) }</p>
                                        <p className="">{ this.renderPrice(price) }</p>
                                        {/* <button className="btn btn--brand mt-lg"
                                            onClick={() => this.handleToggleModal(price)}
                                            disabled={isModalOpen}>Приобрести</button> */}
                                    </div>
                                </div>
                            )}
    
                        </div>
                    </div>
                }

                {/* <div className={isModalOpen ? 'modal open' : 'modal'}>
                    <div className="modal__content">
                        <button className="modal__dismisser" onClick={this.handleToggleModal}><i className="icon-cancel"></i></button>
                        <h2 className="title pb-layout-xs text-center">
                            { t('prices.pay_until') }
                            {selectedPrice.months === 1 && ' ' + selectedPrice.months + t('prices.month')}
                            {selectedPrice.months > 1 && selectedPrice.months < 5 && ' ' + selectedPrice.months + t('prices.month_2')}
                            {selectedPrice.months > 5 && selectedPrice.months < 12 && ' ' + selectedPrice.months + t('prices.month_3')}
                            {selectedPrice.months === 12 && t('prices.year')}
                            <br />{ t('prices.payment_method') }
                        </h2>
                        {getCurrentUser().pp_acc_all && 
                            <p className="text-center pb-sm">
                                { t('prices.active_until') } <b>{new Date(getCurrentUser().pp_acc_all * 1000).toLocaleDateString('ru-RU')}</b>
                            </p>
                        }
                        <div className="modal-boxes">
                            <div className="modal__box" onClick={() => this.handlePurchase('payme')}>
                                <img src={imgPayme} alt="Payme" />
                            </div>
                            <div className="modal__box" onClick={() => this.handlePurchase('click')}>
                                <img src={imgClick} className="click" alt="Click" />
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        )
    }
}

export default withTranslation()(Prices)
