import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { facebookAppId } from 'config.json'
import auth from 'services/authService'
import FacebookLogin from 'react-facebook-login'
import { withTranslation } from 'react-i18next'
import LangSwitcher from 'components/common/LangSwitcher'

class Login extends Component {
    state = {
        login: '',
        password: '',
        errors: {},
        isLoading: false,
        isOpen: false
    }

    componentDidMount() {
        if (auth.getCurrentUser())
            this.props.history.push('/')
    }

    handleSubmit = async e => {
        e.preventDefault()
        const { login, password } = this.state
        const { t } = this.props

        const errors = {}
        if (login === '' || login.length < 3)
            errors.login = t('errors.login')
        else
            delete errors.login

        if (password === '' || password.length < 3)
            errors.password = t('errors.password')
        else
            delete errors.password

        if (Object.keys(errors).length > 0 && errors.constructor === Object)
            return this.setState({errors})
        
        this.setState({errors: {}, isLoading: true})

        const data = { login, password, clientType: 'Web' }

        try {
            const res = await auth.login(data)
            
            if (res && res.status === 200) {
                const { state } = this.props.location
                this.props.history.push(state ? state.from.pathname : '/')
                this.setState({isLoading: false})
            }
        } catch (ex) {
            this.setState({isLoading: false})
        }
    }

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    generateFormClass(name) {
        const { errors } = this.state
        return errors[name] ? 'form-group form-group--icon form-group--error' : 'form-group form-group--icon'
    }

    render() {
        const { isLoading, errors, login, password, isOpen } = this.state
        const { t } = this.props

        const handleResponseFacebook = async res => {
            if (res.status === 'unknown') {
                return
            }

            await auth.loginWithFacebook({ accessToken: res.accessToken, clientType: 'Web' })

            const { state } = this.props.location
            this.props.history.push(state ? state.from.pathname : '/')
        }

        return (
            <div className="login">
                <div className="login-wrap">
                    <div className="login-form">
                        <div className="login__title">
                            <h1>Edu Market</h1>
                            <LangSwitcher />
                        </div>
                        <p className="login__subtitle">{ t('auth.welcome') }</p>
                        <form onSubmit={this.handleSubmit} className="login-card">
                            <div className={this.generateFormClass('login')}>
                                <i className="icon-profile"></i>
                                <input type="text" name="login"
                                    placeholder={ t('input.name') }
                                    className="input input--white"
                                    value={login}
                                    onChange={this.handleChange} />
                                <p className="form-group__label">{errors.login}</p>
                            </div>
                            <div className={this.generateFormClass('password')}>
                                <i className="icon-password"></i>
                                <input type="password" name="password"
                                    placeholder={ t('input.password') }
                                    className="input input--white"
                                    value={password}
                                    onChange={this.handleChange} />
                                <p className="form-group__label">{errors.password}</p>
                            </div>
                            <div className="login__actions">
                                <button type="submit" className="btn btn--brand" disabled={isLoading}>{ t('auth.login') }</button>
                                <FacebookLogin
                                    appId={facebookAppId}
                                    fields="name,email,picture"
                                    callback={handleResponseFacebook}
                                    cssClass="btn btn--brand btn--facebook"
                                    icon="icon-facebook"
                                    textButton=""
                                />
                            </div>
                        </form>
                    </div>
                    <div className="login__bottom">
                        <Link to="/register" className="btn btn--green full-width">{ t('auth.register') }</Link>
                        <button className="btn btn--white-outline btn--text-shadow full-width mt-lg"
                            onClick={() => this.setState({isOpen: true})}>{ t('instructions.name') }</button>
                    </div>
                </div>
                <div className="login-bg"></div>
                <div className={isOpen ? 'login-overlay open' : 'login-overlay'}
                    onClick={() => this.setState({isOpen: false})}></div>
                <div className={isOpen ? 'modal login__modal open' : 'modal login__modal'}>
                    <div className="modal__content modal__content--login">
                        <button className="modal__dismisser" onClick={() => this.setState({isOpen: false})}><i className="icon-cancel"></i></button>
                        <h2 className="title pb-lg text-center">{ t('instructions.title') }</h2>
                        <div className="login__modal-wrap">
                            <p className="login__modal-info"
                                dangerouslySetInnerHTML={{ __html: t('instructions.subtitle') }}></p>
                            <ul className="login__modal-list">
                                <li>
                                    <span>1</span>
                                    <p>{ t('instructions.point_1') }</p>
                                </li>
                                <li>
                                    <span>2</span>
                                    <p>{ t('instructions.point_2') }</p>
                                </li>
                                <li>
                                    <span>3</span>
                                    <p>{ t('instructions.point_3') }</p>
                                </li>
                                <li>
                                    <span>4</span>
                                    <p>{ t('instructions.point_4') }</p>
                                </li>
                                <li>
                                    <span>5</span>
                                    <p>{ t('instructions.point_5') }</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(Login)
