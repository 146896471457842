import React, { useState } from 'react'
import { changePassword } from 'services/authService'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

const ChangePassword = ({ onSubmit }) => {
	const { t } = useTranslation()

	const [data, setData] = useState({
		password: '',
		newPassword: '',
		confirmNewPassword: ''
	})

	const [errors, setErrors] = useState({})

    function generateFormClass(name) {
        return errors[name] ? 'form-group form-group--error' : 'form-group'
	}

	function handleInputChange(e) {
		const newData = {...data}

		newData[e.target.name] = e.target.value
		setData(newData)
	}

	async function handleSaveChanges() {
		const { password, newPassword, confirmNewPassword } = data
		const errorsList = {}

		if (password === '' && newPassword === '' && confirmNewPassword === '')
			return onSubmit()
		
        if (password === '' || password.length < 5)
            errorsList.password = t('errors.password')
        else
            delete errorsList.password

		if (newPassword === '' || newPassword.length < 5)
			errorsList.newPassword = t('password_new')
		else
			delete errorsList.newPassword

		if (confirmNewPassword !== newPassword)
			errorsList.confirmNewPassword = t('password_equal')
		else
			delete errorsList.confirmNewPassword
			
		if (Object.keys(errorsList).length > 0 && errorsList.constructor === Object)
			return setErrors(errorsList)
		
		setErrors({})

		const res = await changePassword(data)

		try {
			if (res && res.status === 200) {
				toast.success(t('toast.success.password'))
				setData({password: '', newPassword: '', confirmNewPassword: ''})
				return onSubmit()
			}
		} catch (error) {
			
		}
	}

	return (
		<React.Fragment>
			<div className="pt-xl pb-sm b-t b-b mb-layout-xs animated fadeInUp">
				<h1 className="subtitle pt-xs pb-lg mb-xs">{ t('profile.change_password') }</h1>
				<div className="inputs-form">
					<div className={generateFormClass('password')}>
						<input type="text" name="password" id="password"
							className="input input--sm"
							onChange={handleInputChange}
							value={data.password}
							placeholder={ t('input.password_old') }
							required />
						<p className="form-group__label">{errors.password}</p>
					</div>
					<div className={generateFormClass('newPassword')}>
						<input type="text" name="newPassword" id="newPassword"
							className="input input--sm"
							onChange={handleInputChange}
							value={data.newPassword}
							placeholder={ t('input.password_new') }
							required />
						<p className="form-group__label">{errors.newPassword}</p>
					</div>
					<div className={generateFormClass('confirmNewPassword')}>
						<input type="text" name="confirmNewPassword" id="confirmNewPassword"
							className="input input--sm"
							onChange={handleInputChange}
							value={data.confirmNewPassword}
							placeholder={ t('input.password_confirm') }
							required />
						<p className="form-group__label">{errors.confirmNewPassword}</p>
					</div>
				</div>
			</div>
			<button onClick={handleSaveChanges}
				className="btn btn--brand btn--long animated fadeInUp">{ t('common.save') }</button>
		</React.Fragment>
	)
}

export default ChangePassword
