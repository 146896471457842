import React, { Component } from 'react'
import { toast } from 'react-toastify'

import { getRelatives, relativesCreate, relativesConfirm, relativesCancel } from 'services/relativesService'
import { getPlayers } from 'services/playersService'
import { getParents } from 'services/parentsService'
import { getPlayersMinStatsById } from 'services/playerStatsService'

import avaFemale from 'assets/images/users/girl.png'
import avaMale from 'assets/images/users/boy.png'
import Preloader from 'components/common/Preloader'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export class Relatives extends Component {
    state = {
        peopleId: '',
        relatives: [],
        stats: undefined,
        newRelatives: [],
        currentPage: 1,
        pageSize: 5,
        searchQuery: '',
        sortColumn: { path: 'peopleId', order: 'asc' },
        isLoading: true,
        isNewRelLoading: true
    }

    async componentDidMount() {
        this.handleGetRelatives()
        this.handleGetNewRelatives()
    }

    async handleGetRelatives() {
        const user = this.props.currentUser
        let res = []

        if (user.rl === 'Parent') {
			res = await getPlayers()
			if (res && res.data) {
				const { data: relatives } = res.data
				if (relatives.length > 0) {
                    for (let index = 0; index < relatives.length; index++) {
                        const el = relatives[index];
                        let res = await getPlayersMinStatsById(el.playerId)
                        if (res && res.data)
                            el.stats = res.data.data
                        else
                            el.stats = undefined
                    }
                }
				return this.setState({ isLoading: false, relatives })
			}
		}
        else if (user.rl === 'Player') {
            res = await getParents()
            if (res && res.data)
                return this.setState({ isLoading: false, relatives: res.data.data })
        }
        
        this.setState({isLoading: false,})
    }

    async handleGetNewRelatives() {

        const res = await getRelatives()
        
        if (res && res.status === 200) {
            if (res.data)
                this.setState({ newRelatives: res.data.data.filter(m => m.status === 'Pending') })
        }
        this.setState({isNewRelLoading: false})
    }

    handleSearchChange = e => {
        this.setState({peopleId: e.target.value})
    }

    handleSearchPeopleClick = async () => {
        const { peopleId } = this.state
        if (peopleId !== '') {
            const res = await relativesCreate(this.state.peopleId)
            if (res && res.status === 200)
                toast.success(this.props.t('toast.success.request_sent'))
                
        }
    }

    handleConfirm = async id => {
        this.setState({isLoading: true, isNewRelLoading: true})
        const res = await relativesConfirm(id)

        if (res && res.status === 200) {
            this.handleGetRelatives()
            this.handleGetNewRelatives()
            toast.success(this.props.t('toast.success.request_added'))
        }
    }

    handleCancel = async id => {
        this.setState({isNewRelLoading: true})
        const res = await relativesCancel(id)
        
        if (res && res.status === 200) {
            this.handleGetNewRelatives()
            toast.success(this.props.t('toast.success.request_rejected'))
        }
    }

	generateAvatar(gender) {
		return gender === 'Female' ? avaFemale : avaMale
    }
    
    generateClass(gender) {
        return gender === 'Female' ? 'relative-card relative-card--long relative-card--female mb-xl' : 'relative-card relative-card--long mb-xl'
    }

    render() {
        const { newRelatives, relatives, isLoading, isNewRelLoading } = this.state
        const { t, currentUser } = this.props

        const renderRelatives = (item) => {
            return (
                <React.Fragment>
                <div>
                    <div className={item.photoPath ? 'relative-card__avatar has-photo' : 'relative-card__avatar'}>
                        <img src={item.photoPath ? item.photoPath : this.generateAvatar(item.gender)} alt="avatar" />
                    </div>
                    <p className="relative-card__name">{item.firstName} {item.lastName}</p>
                    <p className="text">ID {item.peopleId}</p>
                </div>
                {item.stats ? <div className="scores">
                        <div className="score">
                            <div className="score__num">
                                <p>{item.stats.isRightCount}</p>
                                <span role="img" aria-label="Flexed Bicep">💪</span>
                            </div>
                            <p className="score__text" dangerouslySetInnerHTML={{__html: t('scores.quests')}}></p>
                        </div>
                        <div className="score">
                            <div className="score__num">
                                <p>{item.stats.isRightPercentage}%</p>
                                <span role="img" aria-label="Fire">🔥</span>
                            </div>
                            <p className="score__text" dangerouslySetInnerHTML={{__html: t('scores.answers')}}></p>
                        </div>
                        <div className="score">
                            <div className="score__num">
                                <p>{item.stats.totalScore}</p>
                                <span role="img" aria-label="Party Popper">🎉</span>
                            </div>
                            <p className="score__text" dangerouslySetInnerHTML={{__html: t('scores.total')}}></p>
                        </div>
                    </div>
                    : currentUser.rl === 'Parent' ? <p className="text-800">{ t('common.no_player_stats') }</p> : ''
                }
                </React.Fragment>
            )
        }

        return (
            <div className="form">
                <h1 className="title pb-xl">{this.props.currentUser.rl === 'Player' ? t('common.parents') : t('common.children')}</h1>

                <div className="content-card-sm animated fadeInUp mb-layout-xs">
                    <h2 className="text-800 text-default pb-lg mb-xs">{ t('common.add') }</h2>
                    <div className="form-search-group">
                        <div className="form-group mb-0">
                            <input type="number" name="peopleId" id="peopleId"
                                className="input input--sm fixed"
                                onChange={this.handleSearchChange}
                                placeholder={ t('input.user_id') }/>
                        </div>
                        <button className="btn btn--brand btn--icon flex-shrink-0"
                            onClick={this.handleSearchPeopleClick}>
                                <i className="icon-plus-rect"></i>
                        </button>
                    </div>
                </div>

                <div className="content-placeholder">
                    {isLoading
                        ? <Preloader size="lg" centered />
                        : <div className="animated fadeInUp">
                            {relatives.length > 0
                                ? relatives.map(item =>
                                    currentUser.rl === 'Parent'
                                        ? <Link to={{pathname: '/player-stats', state: {
                                            from: this.props.location.pathname,
                                            playerId: item.playerId
                                        }}} className={this.generateClass(item.gender)} key={item.userId}>
                                            {renderRelatives(item)}
                                        </Link>
                                        : <div className={this.generateClass(item.gender)} key={item.userId}>
                                            {renderRelatives(item)}
                                        </div>
                                )
                                : <p className="pl-xl pb-xl">{ t('common.no_relatives') }</p>
                            }
                        </div>
                    }
                </div>
                
                {newRelatives.length > 0 && 
                    <div className="content-placeholder mt-layout-sm">
                        <h2 className="title pb-lg animated fadeInUp">{ t('relatives.requests') }</h2>

                        {isNewRelLoading
                            ? <Preloader size="lg" centered />
                            :<div className="animated fadeInUp">
                                <div className="cards">
                                    {newRelatives.map(item =>

                                        <div className="card" key={item.id}>
                                            <div className="d-flex">
                                                {(item.firstName || item.lastName)
                                                    ? <span>{item.firstName} {item.lastName}</span>
                                                    : <span>ID {item.peopleId} <br />
                                                        <span className="text-gray text-sm font-regular">{ t('relatives.no_name') }</span>
                                                    </span>
                                                }
                                            </div>
                                            {item.status === 'Pending' &&
                                                <div className="card__actions">
                                                    <button data-id={item.id}
                                                        onClick={() => this.handleCancel(item.id)}
                                                        title={ t('relatives.reject') }>
                                                            <i className="icon-cancel-circle"></i>
                                                    </button>
                                                    <button data-id={item.id}
                                                        onClick={() => this.handleConfirm(item.id)}
                                                        title={ t('relatives.accept') }>
                                                            <i className="icon-check-circle"></i>
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    )}
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
        )
    }
}

export default withTranslation()(Relatives)
