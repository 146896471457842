import React from 'react'
import { Link } from 'react-router-dom'
import auth from 'services/authService'
import RegisterSecondaryForm from './RegisterSecondaryForm'

import { getContinents } from 'services/countryService'
import { withTranslation } from 'react-i18next'
import LangSwitcher from 'components/common/LangSwitcher'

class RegisterForm extends React.Component {
    state = {
        login: '',
        password: '',
        userRole: 'parent',
        gender: '',
        continent: '',
        agreement: false,
        birthYear: '',
        countries: [],
        errors: {},
        isMainForm: true,
        isLoading: false,
        roleList: [{ id: 'parent', value: this.props.t('input.parent') }, { id: 'player', value: this.props.t('input.player') }],
        genderList: [{ id: 'male', value: this.props.t('input.male') }, { id: 'female', value: this.props.t('input.female') },]
    }

    async componentDidMount() {
        if (auth.getCurrentUser()) 
            this.props.history.push('/')
        
        try {
            const res = await getContinents()

            if (res && res.status === 200) {
                this.setState({countries: this.translateContinents(res.data.data)})
            }
        } catch (error) {

        }
    }

    translateContinents(data) {
        const { t } = this.props
        return data.map(m => {
            const item = {id: m.id}
            switch (m.name) {
                case 'Africa':
                    item.name = t('continents.africa')
                    break;
                case 'Antarctica':
                    item.name = t('continents.antarctica')
                    break;
                case 'Asia':
                    item.name = t('continents.asia')
                    break;
                case 'Australia':
                    item.name = t('continents.australia')
                    break;
                case 'Europe':
                    item.name = t('continents.europe')
                    break;
                case 'North America':
                    item.name = t('continents.north_america')
                    break;
                case 'South America':
                    item.name = t('continents.south_america')
                    break;
                default:
                    break;
            }
            return item
        })
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        const {login, password, userRole, gender, birthYear, continent, agreement} = this.state
        const { t } = this.props

        const errors = {}
        if (login === '' || login.length < 3)
            errors.login = t('errors.login')
        else if (/^[A-Za-z]+$/.test(login) === false)
            errors.login = t('errors.login_symbols')
        else
            delete errors.login

        if (password === '' || password.length < 3)
            errors.password = t('errors.password')
        else
            delete errors.password

        if (userRole === '')
            errors.userRole = t('errors.role')
        else
            delete errors.userRole

        if (gender === '')
            errors.gender = t('errors.gender')
        else
            delete errors.gender

        if (birthYear === '' || birthYear.length < 4)
            errors.birthYear = t('errors.birthyear_empty')
        else if (parseInt(birthYear) > new Date().getFullYear())
            errors.birthYear = t('errors.birthyear')
        else if (parseInt(birthYear) < new Date('01.01.1950').getFullYear())
            errors.birthYear = t('errors.birthyear_min')
        else
            delete errors.birthYear

        if (continent === '')
            errors.continent = t('errors.continent')
        else
            delete errors.continent
        
        if (agreement === false)
            errors.agreement = t('errors.agreement')

        if (Object.keys(errors).length > 0 && errors.constructor === Object)
            return this.setState({errors})
        
        this.setState({errors: {}, isLoading: true})

        const data = { login, password, userRole, gender, birthYear: parseInt(birthYear), continentId: parseInt(continent) }

        try {
            const res = await auth.register(data)
            
            if (res && res.status === 200) {
                this.setState({isMainForm: false, isLoading: false})
            }
        } catch (ex) {
            this.setState({isLoading: false})
        }
    }

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    handleCheckboxChange = ({ currentTarget: chb }) => {
        const errors = { ...this.state.errors }
        const { t } = this.props
        
        if (chb.value !== 'false') 
            errors.agreement = t('errors.agreement')
        else delete errors.agreement

        this.setState({ agreement: !this.state.agreement, errors })
    }

    handleDatepickerDobChange = date => {
        this.setState({ birthYear: date })
    }

    generateFormClass(name) {
        const { errors } = this.state
        return errors[name] ? 'form-group form-group--icon form-group--error' : 'form-group form-group--icon'
    }

    render() {
        const { countries, errors, agreement, isMainForm, isLoading, genderList } = this.state
        const { t } = this.props

        return (
            <div className="login">
                <div className="login-wrap login-wrap--lg">
                    <div className="login-form">
                        <div className="login__title">
                            <h1>Edu Market</h1>
                            <LangSwitcher />
                        </div>
                        <p className="login__subtitle">{ t('auth.register_title') }</p>
                        <form onSubmit={this.handleSubmit} className="login-card">
                            <div className="login-card__body">
                                {isMainForm
                                    ? <React.Fragment>
                                        <div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className={this.generateFormClass('login')}>
                                                        <input
                                                            onChange={this.handleChange}
                                                            name="login"
                                                            id="login"
                                                            type="text"
                                                            className="input"
                                                            required="required"
                                                            placeholder={ t('input.name') }
                                                        />
                                                        <i className="icon-profile"></i>
                                                        <p className="form-group__label">{errors.login}</p>
                                                    </div>
                                                    <div className={this.generateFormClass('password')}>
                                                        <input
                                                            onChange={this.handleChange}
                                                            name="password"
                                                            id="password"
                                                            type="password"
                                                            placeholder={ t('input.password') }
                                                            className="input"
                                                        />
                                                        <i className="icon-password"></i>
                                                        <p className="form-group__label">{errors.password}</p>
                                                    </div>
                                                    {/* <div className={this.generateFormClass('userRole')}>
                                                        <select name="userRole" id="userRole" className="input"
                                                            onChange={this.handleChange} defaultValue="placeholder">
                                                            <option disabled value="placeholder">{ t('input.role') }</option>
                                                            {roleList.map(option => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.value}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <label htmlFor=""></label>
                                                        <i className="icon-profile"></i>
                                                        <p className="form-group__label">{errors.userRole}</p>
                                                    </div> */}
                                                </div>
                                                <div className="col-md-6">
                                                    <div className={this.generateFormClass('birthYear')}>
                                                        <input
                                                            onChange={this.handleChange}
                                                            name="birthYear"
                                                            id="birthYear"
                                                            type="number"
                                                            className="input"
                                                            required="required"
                                                            placeholder={ t('input.birthyear') }
                                                            title={ t('input.birthyear_helper') }
                                                        />
                                                        <i className="icon-calendar"></i>
                                                        <p className="text-white text-left text mt-sm">{errors.birthYear}</p>
                                                    </div>
                                                    <div className={this.generateFormClass('gender')}>
                                                        <select name="gender" id="gender" className="input"
                                                            onChange={this.handleChange} defaultValue="placeholder">
                                                            <option disabled value="placeholder">{ t('input.gender') }</option>
                                                            {genderList.map(option => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.value}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <label htmlFor=""></label>
                                                        <i className="icon-profile"></i>
                                                        <p className="form-group__label">{errors.gender}</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 offset-md-3">
                                                    <div className={this.generateFormClass('continent')}>
                                                        <select name="continent" id="continent" className="input"
                                                            onChange={this.handleChange} defaultValue="placeholder">
                                                            <option disabled value="placeholder">{ t('input.continent') }</option>
                                                            {countries.map(option => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <label htmlFor=""></label>
                                                        <i className="icon-profile"></i>
                                                        <p className="form-group__label">{errors.continent}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="login__agreement">
                                                <label htmlFor="agreement" className="checkbox">
                                                    <input type="checkbox" name="agreement" id="agreement"
                                                        value={agreement}
                                                        onChange={this.handleCheckboxChange} />
                                                    <span>{ t('input.agreement') }</span>
                                                    {errors.agreement && <p className="text-sm mt-sm">{errors.agreement}</p>}
                                                </label>
                                                <p className="text-sm pt-md" dangerouslySetInnerHTML={{__html: t('auth.agreement')}}></p>
                                            </div>
                                            
                                            <div className="text-center">
                                                <button type="submit"
                                                    className="btn btn--brand px-xl mb-lg"
                                                    disabled={isLoading}>{ t('common.continue') }</button>
                                            </div>
                                        </div>
                                    </React.Fragment>

                                    : <RegisterSecondaryForm />
                                }
                            </div>
                        </form>
                    </div>
                    {isMainForm 
                        && <div className="login__bottom">
                            <Link to="/login" className="btn btn--green full-width">{ t('common.login_to_cab') }</Link>
                        </div>
                    }
                </div>
                <div className="login-bg"></div>
            </div>
        )
    }
}

export default withTranslation()(RegisterForm)
