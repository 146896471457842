export default {
    common: {
        continue: 'Davom etish',
        more: 'Batafsil',
        login_to_cab: 'Kabinetga kirish',
        save: 'Saqlash',
        miss: 'O‘tkazib yuborish',
        logout: 'Chiqish',
        common: 'Umumiy',
        profile: 'Profil',
        stats: 'O‘yinchining statistikasi',
        tariffs: 'Obuna',
        children: 'Bolalar',
        parents: 'Ota-onalar',
        stats_title: 'Statistika',
        add: 'Qo‘shish',
        no_relatives: 'Hali qarindoshlar yo‘q',
        no_stats: 'Statistika yo‘q',
        cta: 'Tarifga haq to‘lang va bonuslarga ega bo‘ling!',
        buy_subscription: 'Obuna sotib oling',
        loading: 'Yuklash',
        sum: 'so‘m',
        no_player_stats: 'Ushbu o‘yinchi haqida statistika yo‘q.',
        apply: 'Qo‘llash',
        congrats: 'Tabriklaymiz!',
        yes: 'Ha',
        cancel: 'Bekor qilish',
        success_deposit: 'Balansingiz to‘ldirildi'
    },
    scores: {
        quests: 'Muvaffaqiyatli <br /> topshiriqlar',
        answers: 'To‘g‘ri <br /> javoblar',
        total: '<br /> vaqt ichidagi ballar'
    },
    profile: {
        my_data: 'Mening ma’lumotlarim',
        photo: 'Profil<br />rasmi',
        change_password: 'Parolni o‘zgartirish',
        fb_title: 'Tez kirish uchun Facebook',
        fb_connect: 'Bo‘glash',
        fb_connected: 'Bo‘glangan',
        fb_reconnect: 'Boshqa akkaunt bog‘lash'
    },
    stats: {
        unavailable: 'Statistika mavjud emas',
        unavailable_info: 'Statistikani ko‘rish uchun obuna a‘zo bo‘lishingiz kerak',
        general: 'Umumiy',
        general_subject: 'Umumiy',
        result: 'Umumiy natija',
        time_spent: 'O‘yinning umumiy vaqti',
        result_avg: 'O‘rtacha natija',
        time_spent_avg: 'O‘yinning o‘rtacha vaqti',
        right: 'To‘g‘ri javoblar',
        wrong: 'Noto‘g‘ri javoblar',
        by_subject: 'Fan bo‘yicha',
        choose_subject: 'Fanni tanglang',
        all_scores: 'Umumiy ball',
        all_time: 'O‘yinning umumiy vaqti',
        all_answers: 'Javoblar ko‘rsatgichi',
        no_subject_stats: 'Bu fan bo‘yicha statistika yo‘q',
        no_theme_stats: 'Bu mavzu bo‘yicha statistika yo‘q',
        no_player_stats: 'Ushbu o‘yinchi uchun statistika yo‘q',
        by_theme: 'Mavzu bo‘yicha',
        choose_theme: 'Mavzu bo‘yicha',
    },
    prices: {
        day: ' kun',
        days: ' kun',
        month_stats: ' oy statistikasi',
        month_stats_2: ' oylik statistika',
        month_stats_3: ' oylar statistikasi',
        year_stats: '1 yillik statistika',
        month: ' oy',
        month_2: ' oy',
        month_3: ' oy',
        year: ' 1 yil',
        payment_method: 'To‘lov usulini tanlang',
        active_until: 'Sizning hozirgi obunangiz shu vaqtgacha faol',
        pay_until: 'Tarif rejasi uchun to‘lov',
        deposit_method: 'Hisobni to‘ldirish usuli',
        deposit: 'Hisobingizni to‘ldirish miqdorni kiriting',
        test_day: 'Sinov kuni',
        free: 'Bepul',
        free_day: 'Bir kunlik bepul obuna',
        get_sub: 'Obunani sotib olish',
        purchase_free_sub: 'Siz bir martalik bepul kunlik obunani sotib olvossiz',
        purchase_sub_1: 'Siz ',
        purchase_sub_2: ' so‘mga sotib olvotganizni tasdiqlaysiz',
        success_sub: 'Siz obunani muvaffaqiyatli sotib oldingiz'
    },
    relatives: {
        requests: 'So‘rovlar',
        no_name: 'Ismi va familiyasi ko‘rsatilmagan',
        reject: 'So‘rovni rad etish',
        accept: 'So‘rovni qabul qilish',
    },
    auth: {
        welcome: 'Ota-onaning shaxsiy kabinetiga xush kelibsiz',
        register_title: 'Registratsiya',
        login: 'Kirish',
        register: 'Registratsiya',
        agreement: `
            "Ro‘yxatdan o‘tish" tugmasini bosish orqali men shaxsiy ma‘lumotlarimni bu yerda ko‘rsatilgan shartlarga muvofiq qayta ishlashga rozilik beraman. Agar siz shaxsiy ma‘lumotlaringizni qayta ishlashdan bosh tortsangiz, <a href="https://edumarket.uz/privacy" class="text-underline" target="_blank" rel="noopener noreferrer">"Ro‘yxatdan o'tish"</a> tugmasini bosmang.`,
    },
    input: {
        name: 'Foydalanuvchi ismi',
        first_name: 'Ism',
        last_name: 'Familiya',
        middle_name: 'Otasining ismi',
        password: 'Parol',
        password_old: 'Eski parol',
        password_new: 'Yangi parol',
        password_confirm: 'Yangi parolni qaytaring',
        phone: 'Telefon raqami',
        phone_helper: 'Telefon raqamingizni to‘liq kodi bilan kiriting',
        role: 'Rol',
        birthyear: ' Tug‘ilgan yili (2009)',
        birthyear_helper: 'Tug‘ilgan yilingizni ko‘rsating, masalan "2009"',
        gender: 'Jins',
        country: 'Mamlakat',
        continent: 'Qit‘a',
        agreement: 'Shaxsiy ma‘lumotlarimni qayta ishlashga rozilik bildiraman.',
        male: 'Erkak',
        female: 'Ayol',
        parent: 'Ota-ona',
        player: 'Bola',
        children: 'Bolalar',
        user_id: 'Foydalanuvchi IDsi',
        payment_sum: 'Hisobni to‘ldirish miqdori'
    },
    errors: {
        login: '"Ism" maydoni bo‘sh va 3 belgidan kam bo‘lmasligi kerak',
        login_symbols: '"Ism" maydonida maxsus belgilar yoki bo‘shliqlar bo‘lmasligi kerak',
        password: '"Parol" maydoni bo‘sh va 5 belgidan kam bo‘lmasligi kerak',
        password_new: '"Yangi parol" maydoni bo‘sh va 5 belgidan kam bo‘lmasligi kerak',
        password_equal: 'Yangi parollar mos bo‘lishi kerak',
        role: '"Rol" maydoni bo‘sh bo‘lmasligi kerak',
        gender: '"Jins" maydoni bo‘sh bo‘lmasligi kerak',
        birthyear_empty: '"Tug‘ilgan yili" maydoni 4 belgidan kam bo‘lmasligi kerak',
        birthyear: '"Tug‘ilgan yili" maydoni hozirgi zamondan katta bo‘lishi mumkin emas',
        birthyear_min: '"Tug‘ilgan yili" maydoni 1950 dan kam bo‘lmasligi kerak',
        country: '"Mamlakat" maydoni bo‘sh bo‘lishi mumkin emas',
        continent: '"Qit‘a" maydoni bo‘sh bo‘lishi mumkin emas',
        agreement: 'Registratsiya uchun ma‘lumotlarni qayta ishlashga o‘z roziligingizni berishingiz kerak.',
        first_name: '"Ism" maydoni bo‘sh bo‘lmasligi kerak',
        first_name_symbols: '"Ism" maydonida maxsus belgilar yoki bo‘shliqlar bo‘lmasligi kerak',
        last_name: '"Familiya" maydoni bo‘sh bo‘lmasligi kerak',
        last_name_symbols: '"Familiya" maydonida maxsus belgilar yoki bo‘shliqlar bo‘lmasligi kerak',
        middle_name: '"Оtasining ismi" maydoni bo‘sh bo‘lmasligi kerak',
        phone: '"Telefon raqami" maydoni bo‘sh bo‘lmasligi kerak',
        phone_limit: '"Telefon raqami" maydoni 12 belgidan iborat bo‘lishi kerak',
        email: '"Email" maydoni bo‘sh bo‘lmasligi kerak',
        email_format: 'Elektron pochta formati noto‘g‘ri',
    },
    toast: {
        success: {
            profile: 'Shaxsiy malumotlar muvaffaqiyatli saqlandi',
            password: 'Parol muvaffaqiyatli o‘zgartirildi',
            request_sent: 'Sizning so‘rovingiz yuborildi',
            request_added: 'Foydalanuvchi qo‘shildi',
            request_rejected: 'Sizning rad etishingiz foydalanuvchi nomiga yuborildi',
            fb_connected: 'Sizning profilingiz muvaffaqiyatli bog‘landi'
        }
    },
    continents: {
        africa: 'Afrika',
        antarctica: 'Antarktida',
        asia: 'Osiyo',
        australia: 'Avstraliya',
        europe: 'Evropa',
        north_america: 'Shimoliy Америка',
        south_america: 'Janubiy Amerika'
    },
    instructions: {
        name: 'Ota-onalar ko‘rsatmalar',
        title: 'Ota-onalarning shaxsiy kabinetdan foydalanish bo‘yicha ko‘rsatmalar',
        subtitle: 'Hurmatli ota-onalar, Edu Market platformasining asosiy afzalliklaridan biri ota-ona uchun shaxsiy kabineti hisoblanadi. Shaxsiy kabinetning funksional imkoniyatlari 5 blokdan iborat:',
        point_1: '"Umumiy" bloki barcha o‘yinchilarning ID raqamlarini o‘z ichiga oladi, qisqa ko‘rsatkichlar (muvaffaqiyatli bajarilgan vazifalar, to‘g‘ri javoblar, barcha vaqt uchun ballar)',
        point_2: '"Profil" bloki ota-onaning shaxsiy ma‘lumotlari, elektron manzili va telefon raqami bilan shaxsiy sahifasini o‘z ichiga oladi',
        point_3: '"O‘yinchi statistikasi" bloki - bolaning natijalarini tahlil qilish (to‘g‘ri va noto‘g‘ri yechimlar nisbati) raqamli va grafik versiyalarda o‘yinchining mantiqiy va umumiy rivojlanish dinamikasining to‘liq ko‘rinishi va sanalar bo‘yicha filtrlash imkoniyati bilan belgilangan vazifalarning murakkabligini ko‘rsatib beradi',
        point_4: '"Ota-onalar" bloki-ID-raqamlari bo‘lgan ota-onalar sahifalari va bola tomonidan bajarilgan vazifalar natijalarining aniq statistikasini olib boradi',
        point_5: '"Tariflar" bloki - statistika xizmatlarini tarifikasiya qilish vaqt davriga qarab-1 oy, 3 oy, olti oy, yil uchun',
    }
}