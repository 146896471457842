import jwtDecode from 'jwt-decode'
import http from './httpService'
import { apiUrl } from '../config.json'
import Cookies from 'js-cookie'

const apiEndpoint = apiUrl + '/auth'
const tokenKey = 'token'
const tokenRefreshKey = 'refreshToken'

// set your Local headers
http.setJwt(getJwt())

export async function login(data) {
    const res = await http.post(apiEndpoint + '/signin', data)
    
    updateJwtAndCookies(res)
    return res
}

export async function register(user) {
    const res = await http.post(apiEndpoint + '/signup', user)

    updateJwtAndCookies(res)
    return res
}

// force quick register
export async function registerQuick() {
    await http.post(apiEndpoint + '/signup/quick')
}

// set Password if he has no any
export async function setPassword(newPassword, confirmNewPassword) {
    await http.post(apiEndpoint + '/setpassword', { newPassword, confirmNewPassword})
}

// change password on user request
export async function changePassword(data) {
    return await http.post(apiEndpoint + '/changepassword', data)
}

export async function dropPassword(peopleId) {
    return await http.post(apiEndpoint + '/droppassword', {peopleId})
}

export async function loginWithFacebook(data) {
    const res = await http.post(apiEndpoint + '/signin/facebook', data)

    updateJwtAndCookies(res)
}

export async function connectFacebook(accessToken) {
    return await http.post(apiUrl + '/people/connect/facebook', { accessToken })
}

export async function registerWithFacebook(accessToken, userRole) {
    const res = await http.post(apiEndpoint + '/signup/facebook', { accessToken, userRole })
    
    updateJwtAndCookies(res)
}

export async function refreshToken() {
    const refreshKey = Cookies.get(tokenRefreshKey)

    if (!refreshKey) {
        Cookies.remove(tokenKey)
        Cookies.remove(tokenRefreshKey)
        return window.location = '/login'
    }

    if (refreshKey) {
        const response = await http.post(apiEndpoint + '/refresh', { 'refreshToken': refreshKey })
        
        if (response === undefined || response.status === 400) {
            Cookies.remove(tokenKey)
            Cookies.remove(tokenRefreshKey)
            return window.location = '/login'
        }

        const { data: res } = response
        // get token
        const jwt = res.data.type + ' ' + res.data.accessToken
        const newRefreshKey = res.data.refreshToken

        Cookies.set(tokenKey, jwt)
        Cookies.set(tokenRefreshKey, newRefreshKey)
    }
}

export function getCurrentUser() {
    try {
        const jwt = Cookies.get(tokenKey)
        // console.log('JWT Decode:', jwtDecode(jwt))

        let current_time = new Date().getTime() / 1000;

        // console.log(current_time)
        // console.log(jwtDecode(jwt).exp)

        if (current_time > jwtDecode(jwt).exp) { // check if current time is more than expiration tine
            const refreshKey = Cookies.get(tokenRefreshKey)
            // console.log('expired')
            
            // check if refresh key actually exists
            if (refreshKey) {
                refreshToken()
            }
            else {
                Cookies.remove(tokenKey)
                return window.location = '/login'
            }
        }

        return jwtDecode(jwt)
    } catch (ex) { 
        return null
    }
}

export function getJwt() {
    return Cookies.get(tokenKey)
}

export function logout() {
    Cookies.remove(tokenKey)
    Cookies.remove(tokenRefreshKey)
}

export function updateJwtAndCookies(res) {
    if (res) {
        // get token
        const jwt = res.data.data.type + ' ' + res.data.data.accessToken
        const refreshKey = res.data.data.refreshToken

        Cookies.set(tokenKey, jwt)
        Cookies.set(tokenRefreshKey, refreshKey)
        http.setJwt(getJwt())

        return res
    }
}

export default {
    login,
    register,
    registerQuick,
    registerWithFacebook,
    loginWithFacebook,
    setPassword,
    refreshToken,
    changePassword,
    dropPassword,
    logout,
    getCurrentUser,
    connectFacebook,
    getJwt
}