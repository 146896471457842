import http from './httpService'
import { apiUrl } from '../config.json'

const apiEndpoint = apiUrl + '/tariff'

export async function getPrices() {
    return http.get(apiEndpoint + '/type')
}

export default {
    getPrices
}