import axios from 'axios'
import logger from './logService'
import auth from './authService'
import { toast } from 'react-toastify'

axios.interceptors.response.use(null,
    error => {
        const expectedError = error.response
            && error.response.status >= 400
            && error.response.status < 500
        
        try {
            if (error.response.status === 401) {
                auth.refreshToken()
            }
        } catch (error) {
            
        }

        if (!expectedError) {
            logger.log(error)
            toast.error('Network error.', error)
        }

        if (expectedError) {
            logger.log(error.response.data)

            if (error.response.data.data) {
                for (let [key, value] of Object.entries(error.response.data.data)) {
                    toast.error(value)
                    return key
                }
            }
            else {
                toast.error(error.response.data.message)
                return error.response
            }
        }

        return Promise.reject(error)
    }
)

function setJwt(jwt) {
    axios.defaults.headers.common['Authorization'] = jwt
    axios.defaults.headers.common['api-version'] = '1.0'
}

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
    setJwt
}