import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import toNumberWithSpaces from 'utilities/toNumberWithSpaces'

import imgCoin from 'assets/images/coin.png'

class Sidebar extends Component {

    render() {
        const { t, profileData, onLinkClick, toggleCoinModal } = this.props

        return (
            <nav className="sidebar">
                <div className="sidebar-nav">
                    <NavLink to="/common" onClick={onLinkClick} className="sidebar__link">
                        <i className="icon-common"></i>
                        <span>{ t('common.common') }</span>
                    </NavLink>
                    <NavLink to="/profile" onClick={onLinkClick} className="sidebar__link">
                        <i className="icon-profile"></i>
                        <span>{ t('common.profile') }</span>
                    </NavLink>
                    <NavLink to="/player-stats" onClick={onLinkClick} className="sidebar__link">
                        <i className="icon-stats"></i>
                        <span>{ t('common.stats') }</span>
                    </NavLink>
                    <NavLink to="/relatives" onClick={onLinkClick} className="sidebar__link">
                        <i className="icon-children"></i>
                        {/* <span>{profileData.role === 'Parent' ? t('common.children') : t('common.parents')}</span> */}
                        <span>{ t('common.children') }</span>
                    </NavLink>
                    <NavLink to="/prices" onClick={onLinkClick} className="sidebar__link">
                        <i className="icon-star"></i>
                        <span>{ t('common.tariffs') }</span>
                    </NavLink>
                    <button className="sidebar__link sidebar__link--coin"
                        onClick={toggleCoinModal}>
                        <img src={imgCoin} alt="coin"/>
                        <span>{ toNumberWithSpaces(profileData.balance) } { t('common.sum') }</span>
                        <i className="icon-plus-circle"></i>
                    </button>
                </div>
            </nav>
        )
    }
}

export default withTranslation()(Sidebar)
