export function formatSubjectData(res) {
	if (res) {
		const { data } = res.data
		const chartSubjectScore = []
		data.map(m =>
			chartSubjectScore.push({ name: m.name, value: m.totalScore })
		)

		const chartSubjectTime = []
		data.map(m =>
			chartSubjectTime.push({ name: m.name, value: m.totalSpentTime })
		)

		const chartSubjectRightCount = []
		data.map(m =>
			chartSubjectRightCount.push({ name: m.name, value: m.isRightCount })
		)

		const chartSubjectWrongCount = []
		data.map(m =>
			chartSubjectWrongCount.push({ name: m.name, value: m.isNotRightCount })
		)

		// const averageSpentTime = []
		// data.map(m =>
		//     averageSpentTime.push({ name: m.name, value: m.averageSpentTime })
		// )

		return { chartSubjectScore, chartSubjectTime, chartSubjectRightCount, chartSubjectWrongCount }
	}
}

export function formatThemeData(res) {
	if (res) {
		const { data } = res.data
		const chartThemeScore = []
		data.map(m =>
			chartThemeScore.push({ name: m.name, value: m.totalScore })
		)

		const chartThemeTime = []
		data.map(m =>
			chartThemeTime.push({ name: m.name, value: m.totalSpentTime })
		)

		const chartThemeRightCount = []
		data.map(m =>
			chartThemeRightCount.push({ name: m.name, value: m.isRightCount })
		)

		const chartThemeWrongCount = []
		data.map(m =>
			chartThemeWrongCount.push({ name: m.name, value: m.isNotRightCount })
		)

		return { chartThemeScore, chartThemeTime, chartThemeRightCount, chartThemeWrongCount }
	}
}

export default {
	formatSubjectData,
	formatThemeData
}