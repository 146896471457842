import http from './httpService'
import { apiUrl } from '../config.json'

const apiEndpoint = apiUrl + '/theme'

function themeUrl(id) {
    return `${apiEndpoint}/${id}`
}

export function getThemes(subjectId) {
    return http.get(apiEndpoint + `/subject/${subjectId}`)
}

export function getTheme(themeId) {
    return http.get(themeUrl(themeId))
}

export function saveTheme(theme) {
    if (theme.id) {
        const body = { ...theme }
        delete body.id

        return http.put(themeUrl(theme.id), body)
    }

    return http.post(apiEndpoint, theme)
}

export function deleteTheme(themeId) {
    return http.delete(themeUrl(themeId))
}