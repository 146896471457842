import axios from 'axios'
import http from './httpService'
import { apiUrl } from '../config.json'

const apiEndpoint = apiUrl + '/photo'

const requestHeaders = {
    'content-type': 'multipart/form-data'
}

export async function photoUpload(file) {
    return await axios.post(apiEndpoint + '/upload', file, { headers: requestHeaders })
}

export async function photoDownload() {
    return await http.get(apiEndpoint + '/download', { responseType: 'arraybuffer'})
}

export default {
    photoUpload,
    photoDownload
}