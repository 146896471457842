import React from 'react'
import { useTranslation } from 'react-i18next'

import iconUz from 'assets/images/icon-uz.svg'
import iconRu from 'assets/images/icon-ru.svg'

const LangSwitcher = () => {
	const { i18n } = useTranslation()

	return (
		<React.Fragment>
			{i18n.language === 'ru'
				? <button className="header__lang header__item link d-flex align-items-center wow fadeIn" onClick={() => i18n.changeLanguage('uz')}>
					<img src={iconUz} alt="country flag" />
				</button>
				: <button className="header__lang header__item link d-flex align-items-center wow fadeIn" onClick={() => i18n.changeLanguage('ru')}>
					<img src={iconRu} alt="country flag" />
				</button>
			}
		</React.Fragment>
	)
}

export default LangSwitcher
