import React, { Component } from 'react'
import { getPlayers } from 'services/playersService'
import PlayerStatsView from './PlayerStatsView'

class PlayerStats extends Component {
    state = {
        players: [],
        currentUser: this.props.currentUser,
        selectedPlayerId: ''
    }

    handleSharePlayerId = id => {
        this.setState({ selectedPlayerId: id })
    }

    async componentDidMount() {
        if (this.state.currentUser.rl !== 'Player') {

            const res = await getPlayers()
    
            if (res && res.status === 200) {
                const { data: players } = res.data
                if (players.length > 0)
                    this.setState({ players, selectedPlayerId: players[0].playerId })
            }
        }
    }

    render() {
        const { players, selectedPlayerId, currentUser } = this.state

        return <PlayerStatsView
                players={players}
                playerId={selectedPlayerId}
                onPlayerSelect={this.handleSharePlayerId}
                userRole={currentUser.rl}
            />
    }
}

export default PlayerStats
