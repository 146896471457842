import React, { Component } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { getProfile, getBalance } from 'services/peopleService'

import Header from './global/Header'
import Common from './Common'
import Profile from './Profile'
import Relatives from './Relatives'
import PlayerStats from './statistics/PlayerStats'
import Prices from './Prices'
import CoinModal from './global/CoinModal'

const _body = document.querySelector('body')
const _overlay = document.querySelector('.overlay')

class Content extends Component {
    state = {
        profileData: {},
        isCoinModalOpen: false
    }

    async componentDidMount() {
        this.fetchProfileData()

        _overlay.addEventListener('click', this.handleToggleCoinModal)
    }

    async fetchProfileData() {
        const res = await getProfile()
        if (res && res.status === 200)
            this.setState({ profileData: res.data.data })
    }

    handleUpdateBalance = async () => {
        const res = await getBalance()
        if (res && res.status === 200)
            this.setState({
                profileData: {
                    ...this.state.profileData,
                    balance: res.data.data
                }
            })
    }

    handleSetNewAvatar = (avatar) => {
        this.setState({profileData: {...this.state.profileData, photoPath: avatar}})
    }

    handleToggleCoinModal = () => {
        if (!this.state.isCoinModalOpen) {
            _body.classList.add('o-hidden')
            _overlay.classList.add('open')
            this.setState({isCoinModalOpen: true})
        }
        else {
            _body.classList.remove('o-hidden')
            _overlay.classList.remove('open')
            this.setState({isCoinModalOpen: false})
        }
    }

    render() {
        const { currentUser } = this.props
        const { profileData, isCoinModalOpen } = this.state

        return (
            <React.Fragment>
                <section className="wrapper">
                    <Header currentUser={currentUser} profileData={profileData}
                        toggleCoinModal={this.handleToggleCoinModal} />
                    <div className="content">
                        <Switch>
                            <Route path="/common" exact
                                render={props => <Common {...props} currentUser={currentUser} />} />
                            <Route path="/profile"
                                render={props => <Profile {...props}
                                    profileData={profileData} setNewAvatar={this.handleSetNewAvatar}/>} />
                            <Route path="/player-stats/:id?"
                                render={props => <PlayerStats {...props} currentUser={currentUser} />} />
                            <Route path="/relatives"
                                render={props => <Relatives {...props} currentUser={currentUser} />} />
                            <Route path="/prices"
                                render={props => <Prices {...props} profileData={profileData}
                                    onPayment={this.handleUpdateBalance} />} />
                            <Redirect to="/common" />
                        </Switch>
                    </div>
                    <CoinModal isOpen={isCoinModalOpen} toggleModal={this.handleToggleCoinModal}
                        profileData={profileData} />
                </section>
            </React.Fragment>
        )
    }
}

export default Content
