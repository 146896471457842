import React, { Component } from 'react'
import Preloader from 'components/common/Preloader'
import { getPlayers } from 'services/playersService'
import { getParents } from 'services/parentsService'
import {
  getSelfStats,
  getPlayersMinStatsById
} from 'services/playerStatsService'
import { Link, withRouter } from 'react-router-dom'
import { getCurrentUser } from 'services/authService'
import { withTranslation } from 'react-i18next'
import queryString from 'query-string'

import avaFemale from 'assets/images/users/girl.png'
import avaMale from 'assets/images/users/boy.png'
import imgEmojis from 'assets/images/emoji-group.svg'
import imgStats from 'assets/images/stats.svg'
import Swal from 'sweetalert2'

export class Common extends Component {
  state = {
    relatives: [],
    stats: undefined,
    isLoading: true,
    isStatLoading: true,
    selectedPlayerId: undefined
  }

  async componentDidMount() {
    const { t } = this.props
    this.handleGetRelatives()

    let params = queryString.parse(this.props.location.search)
    if (params && params.payment_status === '2') {
      Swal.fire(t('common.congrats'), t('common.success_deposit'), 'success')
      this.props.history.replace('/common')
    }
  }

  async handleGetRelatives() {
    const user = this.props.currentUser
    let res = []

    if (user.rl === 'Parent') {
      res = await getPlayers()
      if (res && res.data) {
        const { data: relatives } = res.data
        this.setState({ relatives })
        if (relatives.length > 0) {
          this.fetchPlayerStats(relatives[0].playerId)
          this.setState({ isLoading: false }, () => {
            if (this.state.relatives.length > 0)
              document.querySelector(`.relative-card`).classList.add('active')
          })
        }
        return this.setState({ isLoading: false })
      }
    } else if (user.rl === 'Player') {
      res = await getParents()
      if (res && res.data) this.setState({ relatives: res.data.data })
      this.fetchPlayerStats()
      return this.setState({ isLoading: false })
    }
    return this.setState({ isLoading: false })
  }

  async fetchPlayerStats(playerId) {
    this.setState({ selectedPlayerId: playerId, isStatLoading: true })
    let stats = []
    if (playerId) stats = await getPlayersMinStatsById(playerId)
    else stats = await getSelfStats()

    if (stats && stats.data) {
      const { data } = stats.data
      if (data) this.setState({ stats: data })
      else this.setState({ stats: undefined })
    }
    return this.setState({ isStatLoading: false })
  }

  handleRelativeClick = async id => {
    if (this.props.currentUser.rl === 'Parent' && !this.state.isStatLoading) {
      let cards = document.getElementsByClassName('relative-card')
      for (var i = 0; i < cards.length; i++) {
        cards[i].classList.remove('active')
      }
      document
        .querySelector(`.relative-card[data-id="${id}"`)
        .classList.add('active')
      this.fetchPlayerStats(id)
    }
  }

  generateAvatar(gender) {
    return gender === 'Female' ? avaFemale : avaMale
  }

  generateRelativeCardClass(gender) {
    const canActive =
      this.props.currentUser.rl === 'Parent' ? 'relative-card--can-active' : ''
    return gender === 'Female'
      ? `relative-card ${canActive} relative-card--female mb-xl`
      : `relative-card ${canActive} mb-xl`
  }

  render() {
    const {
      relatives,
      stats,
      isLoading,
      isStatLoading,
      selectedPlayerId
    } = this.state
    const { t, currentUser } = this.props

    return (
      <div>
        <h2 className="title pb-lg">{t('common.stats_title')}</h2>
        {currentUser.rl === 'Parent' && (
          <div className="p-sm content-placeholder">
            {isLoading ? (
              <Preloader size="lg" centered />
            ) : relatives.length > 0 ? (
              <React.Fragment>
                <div className="row animated fadeInUp">
                  {relatives.map(item => (
                    <div
                      className="col-xl-4 col-sm-6 col-xs-12"
                      key={item.userId}
                    >
                      <div
                        className={this.generateRelativeCardClass(item.gender)}
                        data-id={item.playerId}
                        onClick={() => this.handleRelativeClick(item.playerId)}
                      >
                        <div>
                          <div
                            className={
                              item.photoPath
                                ? 'relative-card__avatar has-photo'
                                : 'relative-card__avatar'
                            }
                          >
                            <img
                              src={
                                item.photoPath
                                  ? item.photoPath
                                  : this.generateAvatar(item.gender)
                              }
                              alt="avatar"
                            />
                          </div>
                          <p className="relative-card__name">
                            {item.firstName} {item.lastName}
                          </p>
                          <p className="text">ID {item.peopleId}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="col-xl-4 col-sm-6 col-xs-12">
                    <Link
                      to="/relatives"
                      className="relative-card relative-card--add-new mb-xl"
                    >
                      <div>
                        <div className="relative-card__emojis">
                          <img src={imgEmojis} alt="emojis" />
                        </div>
                        <p className="relative-card__add">
                          <i className="icon-plus-circle"></i>
                          <span>{t('common.add')}</span>
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <p className="pl-xl pb-xl">{t('common.no_relatives')}</p>
            )}
          </div>
        )}
        <div className="content-placeholder">
          {(relatives.length > 0 || currentUser.rl === 'Player') && (
            <div className="animated fadeInUp content-placeholder">
              {isStatLoading ? (
                <Preloader size="lg" centered />
              ) : stats ? (
                <div className="p-sm scores-wrap mb-xl animated fadeInUp">
                  <div className="score score--lg mb-lg">
                    <div className="score__num">
                      <span role="img" aria-label="Flexed Bicep">
                        💪
                      </span>
                      <p>{stats.isRightCount}</p>
                    </div>
                    <p
                      className="score__text"
                      dangerouslySetInnerHTML={{ __html: t('scores.quests') }}
                    ></p>
                  </div>
                  <div className="score score--lg mb-lg">
                    <div className="score__num">
                      <span role="img" aria-label="Fire">
                        🔥
                      </span>
                      <p>{stats.isRightPercentage}%</p>
                    </div>
                    <p
                      className="score__text"
                      dangerouslySetInnerHTML={{ __html: t('scores.answers') }}
                    ></p>
                  </div>
                  <div className="score score--lg mb-lg">
                    <div className="score__num">
                      <p>{stats.totalScore}</p>
                      <span role="img" aria-label="Party Popper">
                        🎉
                      </span>
                    </div>
                    <p
                      className="score__text"
                      dangerouslySetInnerHTML={{ __html: t('scores.total') }}
                    ></p>
                  </div>
                  {currentUser.rl === 'Parent' ? (
                    <Link
                      to={{
                        pathname: '/player-stats/',
                        state: {
                          from: this.props.location.pathname,
                          playerId: selectedPlayerId
                        }
                      }}
                      className="score score--empty mb-lg"
                    >
                      <img src={imgStats} className="score__img" alt="stats" />
                      <p className="score__action">
                        <i className="icon-arrow-circle-right"></i>
                        <span>{t('common.stats_title')}</span>
                      </p>
                    </Link>
                  ) : (
                    <Link
                      to="/player-stats"
                      className="score score--empty mb-lg"
                    >
                      <img src={imgStats} className="score__img" alt="stats" />
                      <p className="score__action">
                        <i className="icon-arrow-circle-right"></i>
                        <span>{t('common.stats_title')}</span>
                      </p>
                    </Link>
                  )}
                </div>
              ) : (
                <p className="mb-xl">{t('common.no_stats')}</p>
              )}
            </div>
          )}
        </div>

        {!getCurrentUser().pp_acc_all && (
          <Link to="/prices" className="cta">
            <span>{t('common.cta')}</span>
          </Link>
        )}
      </div>
    )
  }
}

export default withRouter(withTranslation()(Common))
