import http from './httpService'
import { apiUrl } from '../config.json'

const apiEndpoint = apiUrl + '/player'

export async function getPlayers(page = 1, pageSize = 12) {
    return http.get(`${apiEndpoint}?page=${page}&pageSize=${pageSize}`)
}

export async function getPlayerById(playerId) {
    return http.get(`${apiEndpoint}/${playerId}`)
}

export async function getPlayerByPeopleId(peopleId) {
    return http.get(`${apiEndpoint}/people/${peopleId}`)
}

export default {
    getPlayers,
    getPlayerById,
    getPlayerByPeopleId
}