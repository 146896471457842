import http from './httpService'
import { apiUrl } from '../config.json'

const apiEndpoint = apiUrl + '/country'

export async function getCountries() {
    return http.get(apiEndpoint)
}

export async function getContinents() {
    return http.get(apiUrl + '/continent')
}

export default {
    getCountries,
    getContinents
}