import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { editProfile } from 'services/peopleService'
import { useTranslation } from 'react-i18next'
import { IMaskInput } from 'react-imask'

const RegisterSecondaryForm = () => {
	const [data, setData] = useState({
		firstName: '',
		lastName: '',
		middleName: '',
		phoneMobile: '',
		email: ''
	})
	const [errors, setErrors] = useState({})
	const [hasValue, setHasValue] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const { t } = useTranslation()
	let history = useHistory()

	async function handleSubmit() {
		const { firstName, lastName, middleName, phoneMobile, email } = data

        const errorsList = {}
        if (firstName === '')
            errorsList.firstName = t('errors.first_name')
        else if (/^[A-Za-zА-Яа-я]+$/.test(firstName) === false)
            errorsList.firstName = t('errors.first_name_symbols')
        else
			delete errorsList.firstName
		
		if (lastName === '')
			errorsList.lastName = t('errors.last_name')
		else if (/^[A-Za-zА-Яа-я]+$/.test(lastName) === false)
			errorsList.lastName = t('errors.last_name_symbols')
		else
			delete errorsList.lastName
	
		if (middleName === '')
			errorsList.middleName = t('errors.middle_name')
		else
			delete errorsList.middleName
	
		if (phoneMobile !== '') {
			if (phoneMobile.length < 12 || phoneMobile.length > 12)
				errorsList.phoneMobile = t('errors.phone_limit')
			else
				delete errorsList.phoneMobile
		}
	
		if (email === '')
			errorsList.email = t('errors.email')
		// eslint-disable-next-line
		else if (/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/.test(email) === false)
			errorsList.email = t('errors.email_format')
		else
			delete errorsList.email
		if (Object.keys(errorsList).length > 0 && errorsList.constructor === Object)
			return setErrors(errorsList)
        
		setErrors({})
		setIsLoading(true)
		
		try {
			const res = await editProfile(data)
			
			if (res && res.status === 200)
				history.push('/')
				
		} catch (error) {
			setIsLoading(false)
		}
	}

    function generateFormClass(name) {
        return errors[name] ? 'form-group form-group--icon form-group--error' : 'form-group form-group--icon'
	}

	function handleChange(e) {
        setData({...data, [e.target.name]: e.target.value})
	}

	function checkForInputs() {
		let hasValue = false
		Object.values(data).forEach(element => {
			if (element !== '') return hasValue = true
		});

		setHasValue(hasValue)
	}
	
	return (
		<div>
			<div className="row">
				<div className="col">
					<div className={generateFormClass('firstName')}>
						<input
							onChange={handleChange}
							name="firstName"
							id="firstName"
							type="text"
							className="input"
							onBlur={checkForInputs}
							required="required"
							placeholder={ t('input.first_name') }
						/>
						<i className="icon-profile"></i>
						<p className="form-group__label">{errors.firstName}</p>
					</div>
					<div className={generateFormClass('lastName')}>
						<input
							onChange={handleChange}
							name="lastName"
							id="lastName"
							type="text"
							className="input"
							onBlur={checkForInputs}
							required="required"
							placeholder={ t('input.last_name') }
						/>
						<i className="icon-profile"></i>
						<p className="form-group__label">{errors.lastName}</p>
					</div>
					<div className={generateFormClass('middleName')}>
						<input
							onChange={handleChange}
							name="middleName"
							id="middleName"
							type="text"
							className="input"
							onBlur={checkForInputs}
							required="required"
							placeholder={ t('input.middle_name') }
						/>
						<i className="icon-profile"></i>
						<p className="form-group__label">{errors.middleName}</p>
					</div>
				</div>
				<div className="col">
					<div className={generateFormClass('phoneMobile')}>
						<IMaskInput
							mask='+{998} 00 000-00-00'
							radix="."
							value={data.phoneMobile}
							unmask={true}
							className="input"
							onAccept={
								(value, mask) => setData({...data, phoneMobile: mask._unmaskedValue})
							}
							onBlur={checkForInputs}
							placeholder={ t('input.phone') }
						/>
						<i className="icon-profile"></i>
						<p className="form-group__label">{errors.phoneMobile}</p>
					</div>
					<div className={generateFormClass('email')}>
						<input
							onChange={handleChange}
							name="email"
							id="email"
							type="text"
							className="input"
							onBlur={checkForInputs}
							required="required"
							placeholder="Email"
						/>
						<i className="icon-profile"></i>
						<p className="form-group__label">{errors.email}</p>
					</div>
				</div>
			</div>
			{hasValue
				? <button type="button"
				className="btn btn--brand px-xl mb-lg"
				onClick={handleSubmit}
				disabled={isLoading}>{ t('common.save') }</button>

				: <Link to="/" className="btn btn--brand px-xl mb-lg">{ t('common.miss') }</Link>
			}
		</div>
	)
}

export default RegisterSecondaryForm
