
function init() {

}

function log(error) {
    // console.log('Logging the error', error)
}

export default {
    init,
    log
}