import React, { Component } from 'react'
import Select from 'react-select'
import { withRouter } from 'react-router-dom'
import { getSubjects } from 'services/subjectService'
import { getPlayerStats, getSelfStats, getSelfStatsBySubject,
		getPlayerStatsBySubject, getSelfStatsByTheme, getPlayerStatsByTheme
	} from 'services/playerStatsService'
import { getThemes } from 'services/themeService'
import { getCurrentUser } from 'services/authService';
import { formatSubjectData, formatThemeData } from 'utilities/dataFormatters'
import { secondsToHms } from 'utilities/timeConverter'
import { withTranslation } from 'react-i18next'
import { DatePickerInput } from 'rc-datepicker'
import 'moment/locale/ru.js'
import 'moment/locale/uz-latn.js'

import ChartBar from './PlayerChartBar'
import ChartStackedBar from './PlayerChartStackedBar'
import Preloader from 'components/common/Preloader'
import LockedStats from './LockedStats'

import avaFemale from 'assets/images/users/girl.png'
import avaMale from 'assets/images/users/boy.png'

class PlayerStatsView extends Component {
    state = {
        data: '',
        subjects: [],
		themes: [],
		
		selectedSubject: '',
		selectedSubjectSort: 'score',

		chartSubjectScore: [],
		chartSubjectTime: [],
		chartSubjectAnswers: [],
		chartSubjectRightCount: [],
		chartSubjectWrongCount: [],

        selectedThemeSubject: '',
        selectedTheme: '',
		selectedThemeSort: 'score',

		chartThemeScore: [],
		chartThemeTime: [],
		chartThemeAnswers: [],
		chartThemeRightCount: [],
		chartThemeWrongCount: [],
		
		userRole: this.props.userRole,
		selectedPlayer: {
			value: this.props.playerId,
			label: this.props.playerId
		},
		isLoading: true,
		isSubjectLoading: true,
		isThemeLoading: true,

		isMobile: false,

		bySubjectStartDate: new Date('2019-01-01'),
		bySubjectEndDate: new Date(),
		byThemeStartDate: new Date('2019-01-01'),
		byThemeEndDate: new Date(),
		datepickerLocale: this.props.i18n.language === 'ru' ? 'ru' : 'uz-latn'
    }

    async componentDidMount() {
		const { userRole } = this.state
		if (window.outerWidth < 769)
			this.setState({isMobile: true})
			
		if (!getCurrentUser().pp_acc_all) return

        const subjects = await getSubjects()
        if (subjects)
			this.setState({ subjects: subjects.data.data })
			
        if (userRole === 'Player') {
            const playerStats = await getSelfStats()
            if (playerStats && playerStats.data) {
				const { data } = playerStats.data
                this.setState({ data, isLoading: false })
				this.fetchAndSetInitialValue(data)
            }
		}
	}

	async componentDidUpdate(prevProps, prevState) {
		if (!getCurrentUser().pp_acc_all) return
		const { userRole } = this.state

		if (userRole !== 'Player' && prevProps.playerId !== this.props.playerId) {
			const { state } = this.props.location

			let currentPlayerId
			let player
			if (state && (state.from === '/common' || state.from === '/relatives'))
				currentPlayerId = state.playerId
			else
				currentPlayerId = this.props.playerId

			player = this.props.players.find(m => m.playerId === currentPlayerId)
			
			this.setState({selectedPlayer: {
				value: currentPlayerId,
				label: player.firstName ? player.firstName : player.peopleId
			}}, async () => {
			
				const playerStats = await getPlayerStats(currentPlayerId)
				if (playerStats && playerStats.data) {
					const { data } = playerStats.data
					this.setState({ data, isLoading: false })
					this.fetchAndSetInitialValue(data)
				}
			})
		}
	}

	async fetchAndSetInitialValue(data) {
		if (data.length > 0) {
			const selectedSubject = data.find(m => m.name === 'General').id
			this.setState({ selectedSubject, selectedThemeSubject: selectedSubject },
				() => this.handleFetchBySubject())
			
			const themes = await getThemes(selectedSubject)
			if (themes && themes.data && themes.data.data.length > 0)
				this.setState({themes: themes.data.data, selectedTheme: themes.data.data[0].id},
					() => this.handleFetchByTheme())
		}
	}

	handleSubjectSelectChange = e => {
		this.setState({ [e.target.name]: e.target.value },
			() => this.handleFetchBySubject())
	}

    handleFetchBySubject = async () => {
		this.setState({ isSubjectLoading: true })
		
		const { userRole, selectedSubject, selectedPlayer, bySubjectStartDate, bySubjectEndDate } = this.state
		const playerId = selectedPlayer.value || this.props.playerId

        if (userRole === 'Player' && selectedSubject !== '') {
			const res = await getSelfStatsBySubject(selectedSubject, bySubjectStartDate, bySubjectEndDate)
            this.setState({...formatSubjectData(res), isSubjectLoading: false})
        }

        if (playerId !== '' && selectedSubject !== '' && userRole !== 'Player') {
            const res = await getPlayerStatsBySubject(playerId, selectedSubject, bySubjectStartDate, bySubjectEndDate)
            this.setState({...formatSubjectData(res), isSubjectLoading: false})
        }
		this.setState({ isSubjectLoading: false })
    }

	handleThemeSelectChange = async e => {
		const name = e.target.name
		const value = e.target.value
		
		if (name === 'selectedThemeSubject') {
			const themes = await getThemes(value)
			this.setState({ themes: themes.data.data, [name]: value },
				() => this.handleFetchByTheme())
		}
		else
			this.setState({ [name]: value }, () => this.handleFetchByTheme())
	}

    handleFetchByTheme = async () => {
		this.setState({ isThemeLoading: true })
		const { userRole, selectedThemeSubject, selectedTheme, selectedPlayer, byThemeStartDate, byThemeEndDate } = this.state
		const playerId = selectedPlayer.value || this.props.playerId

        if (userRole === 'Player' && selectedThemeSubject !== '' && selectedTheme !== '') {
            const res = await getSelfStatsByTheme(selectedTheme, byThemeStartDate, byThemeEndDate)
            this.setState({...formatThemeData(res), isThemeLoading: false})
        }

        if (playerId !== '' && selectedThemeSubject !== '' && selectedTheme !== '' && userRole !== 'Player') {
            const res = await getPlayerStatsByTheme(playerId, selectedTheme, byThemeStartDate, byThemeEndDate)
            this.setState({...formatThemeData(res), isThemeLoading: false})
        }
		this.setState({ isThemeLoading: false })
    }

	generateAvatar(gender) {
		return gender === 'Female' ? avaFemale : avaMale
	}

	handlePlayerSelect = (selectedPlayer) => {
		this.setState({selectedPlayer, isLoading: true})
		this.props.onPlayerSelect(selectedPlayer.value)
	}

	checkForAccess = () => {
		const user = getCurrentUser()
		
		if (!user.pp_acc_all) return false

		if (user.pp_acc_all) {
			const expDateString = user.pp_acc_all.substr(user.pp_acc_all.indexOf(':') + 1)
			const expDate = new Date(expDateString * 1000)

			if (new Date() > expDate) return false
		}
		return true
	}
	
	render() {
		const { data, userRole, subjects, themes, selectedSubject, selectedSubjectSort, selectedThemeSort,
			selectedThemeSubject, selectedTheme, selectedPlayer,
			chartSubjectScore, chartSubjectTime, chartSubjectWrongCount, chartSubjectRightCount,
			chartThemeScore, chartThemeTime, chartThemeWrongCount, chartThemeRightCount,
			isLoading, isSubjectLoading, isThemeLoading, isMobile, datepickerLocale,
			bySubjectStartDate, bySubjectEndDate, byThemeStartDate, byThemeEndDate
		} = this.state
		const { t, i18n } = this.props

		if (!this.checkForAccess()) return <LockedStats />

		return (
			<div className="stats">
				<div className="d-flex align-items-center pb-lg">
					<h2 className="title mr-lg">{ t('common.stats_title') }</h2>
					{userRole === 'Parent' && 
						<div className="flex-shrink-0" style={{minWidth: 100}}>
							<Select
								value={selectedPlayer}
								isDisabled={isLoading}
								onChange={this.handlePlayerSelect}
								loadingMessage={ t('common.loading') }
								placeholder={ t('input.children') }
								className="react-select"
								classNamePrefix="react-select"
								options={this.props.players && this.props.players.map(m => {
									return {
										value: m.playerId,
										label: m.firstName ? m.firstName : m.peopleId
									}
								})}
							/>
						</div>
					}
				</div>

				<div className="content-card content-placeholder content-placeholder--lg content-card--wide-sm">
				{isLoading
					? <Preloader size="lg" centered />
					: data && data.length > 0
						? <React.Fragment>

							<div className="stats-form pt-0 animated fadeInUp">
								<p className="font-bold pb-lg">{ t('stats.general') }</p>
								{data.length > 0
									&& data.map(m => <div className="stats-general" key={m.id}>
										<div className="stats-general__col">
											<div className="stats-general__item">
												<p>{ t('stats.result') }</p>
												<p>{m.totalScore}</p>
											</div>
											<div className="stats-general__item">
												<p>{ t('stats.time_spent') }</p>
												<p>{secondsToHms(m.totalSpentTime, i18n.language)}</p>
											</div>
										</div>
										<div className="stats-general__col">
											<div className="stats-general__item">
												<p>{ t('stats.result_avg') }</p>
												<p>{m.averageScore}</p>
											</div>
											<div className="stats-general__item">
												<p>{ t('stats.time_spent_avg') }</p>
												<p>{secondsToHms(m.averageSpentTime, i18n.language)}</p>
											</div>
										</div>
										<div className="stats-general__col">
											<div className="stats-general__item">
												<p>{ t('stats.right') }</p>
												<p>{m.isRightCount}</p>
											</div>
											<div className="stats-general__item">
												<p>{ t('stats.wrong') }</p>
												<p>{m.isNotRightCount}</p>
											</div>
										</div>
									</div>
								)}
							</div>
							
							<div className="stats-form animated fadeInUp">
								<p className="font-bold pb-lg">{ t('stats.by_subject') }</p>
								<div className="stats__input-group">
									<div className="form-group">
										<select className="input input--sm"
											value={selectedSubject}
											name="selectedSubject"
											onChange={this.handleSubjectSelectChange}>
											<option value="" disabled>{ t('stats.choose_subject') }</option>
											{subjects.map(m => <option key={m.id}
												value={m.id}>{m.name && m.name === 'General' ? t('stats.general_subject') : m.name}</option>)}
										</select>
										<label htmlFor="subject"></label>
									</div>
									<div className="form-group">
										<select className="input input--sm"
											defaultValue={selectedSubjectSort}
											name="selectedSubjectSort"
											onChange={e => this.setState({selectedSubjectSort: e.target.value})}>
											<option value="score">{ t('stats.all_scores') }</option>
											<option value="time">{ t('stats.all_time') }</option>
											<option value="answers">{ t('stats.all_answers') }</option>
										</select>
										<label htmlFor="subject"></label>
									</div>
								</div>
								<div className="d-flex align-items-center flex-wrap pb-layout-xs">
									<DatePickerInput
										locale={datepickerLocale}
										value={bySubjectStartDate}
										onChange={val => this.setState({bySubjectStartDate: val})}
										disabled={isLoading}
										className="mr-md mb-sm"
									/>
									<DatePickerInput
										locale={datepickerLocale}
										value={bySubjectEndDate}
										onChange={val => this.setState({bySubjectEndDate: val})}
										disabled={isLoading}
										className="mr-md mb-sm"
									/>
									<button className="btn btn--brand btn--sm mb-sm"
										disabled={isLoading}
										onClick={this.handleFetchBySubject}>{ t('common.apply') }</button>
								</div>
								<div className="content-placeholder">
									{isSubjectLoading
										? <Preloader size="lg" centered />
										: selectedSubject !== '' ? <React.Fragment>
											{selectedSubjectSort === 'score'
												&& <ChartBar data={chartSubjectScore} isMobile={isMobile} />}
											{selectedSubjectSort === 'time'
												&& <ChartBar data={chartSubjectTime} isTime={true} isMobile={isMobile} />}
											{selectedSubjectSort === 'answers'
												&& <ChartStackedBar
													isMobile={isMobile}
													rightCount={chartSubjectRightCount}
													wrongCount={chartSubjectWrongCount} />}
										</React.Fragment>
										: <p>{ t('stats.no_subject_stats') }</p>
									}
								</div>
							</div>
							
							<div className="stats-form animated fadeInUp">
								<p className="font-bold pb-lg">{ t('stats.by_theme') }</p>
								<div className="stats__input-group">
									<div className="form-group">
										<select className="input input--sm"
											value={selectedThemeSubject}
											name="selectedThemeSubject"
											onChange={this.handleThemeSelectChange}>
											<option value="" disabled>{ t('stats.choose_subject') }</option>
											{subjects.map(m => <option key={m.id}
												value={m.id}>{m.name && m.name === 'General' ? t('stats.general_subject') : m.name}</option>)}
										</select>
										<label htmlFor="subject"></label>
									</div>
									<div className="form-group">
										<select className="input input--sm"
											value={selectedTheme}
											name="selectedTheme"
											onChange={this.handleThemeSelectChange}>
											<option value="" disabled>{ t('stats.choose_theme') }</option>
											{themes.map(m => <option key={m.id}
												value={m.id}>{m.name}</option>)}
										</select>
										<label htmlFor="subject"></label>
									</div>
									<div className="form-group">
										<select className="input input--sm"
											defaultValue={selectedThemeSort}
											name="selectedThemeSort"
											onChange={e => this.setState({selectedThemeSort: e.target.value})}>
											<option value="score">{ t('stats.all_scores') }</option>
											<option value="time">{ t('stats.all_time') }</option>
											<option value="answers">{ t('stats.all_answers') }</option>
										</select>
										<label htmlFor="subject"></label>
									</div>
								</div>
								<div className="d-flex align-items-center flex-wrap pb-layout-xs">
									<DatePickerInput
										locale={datepickerLocale}
										value={byThemeStartDate}
										onChange={val => this.setState({byThemeStartDate: val})}
										disabled={isLoading}
										className="mr-md mb-sm"
									/>
									<DatePickerInput
										locale={datepickerLocale}
										value={byThemeEndDate}
										onChange={val => this.setState({byThemeEndDate: val})}
										disabled={isLoading}
										className="mr-md mb-sm"
									/>
									<button className="btn btn--brand btn--sm mb-sm"
										disabled={isLoading}
										onClick={this.handleFetchByTheme}>{ t('common.apply') }</button>
								</div>
								<div className="content-placeholder">
									{isThemeLoading
										? <Preloader size="lg" centered />
										: (selectedThemeSubject !== '' && selectedTheme !== '')
											? <React.Fragment>
											{selectedThemeSort === 'score'
												&& <ChartBar data={chartThemeScore} isMobile={isMobile} />}
											{selectedThemeSort === 'time'
												&& <ChartBar data={chartThemeTime} isTime={true} isMobile={isMobile} />}
											{selectedThemeSort === 'answers'
												&& <ChartStackedBar
													isMobile={isMobile}
													rightCount={chartThemeRightCount}
													wrongCount={chartThemeWrongCount} />}
										</React.Fragment>
										: <p>{ t('stats.no_subject_stats') }</p>
									}
								</div>
							</div>
						</React.Fragment>
						: <div className="content-placeholder">{ t('stats.no_player_stats') }</div>
				}
				</div>
			</div>
		)
	}
}

export default withTranslation()(withRouter(PlayerStatsView))
