import http from './httpService'
import { apiUrl } from '../config.json'

const apiEndpoint = apiUrl + '/payment'

export async function createPayment(tariffId) {
    return http.post(apiEndpoint + '/' + tariffId)
}

export default {
    createPayment
}